import { useEffect, useState } from "react";
import { useTokensFromStorage } from "../../contexts/token.context";
import Const from "../../constants";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserFromStorage } from "../../contexts/user.context";
import { isEmpty } from "lodash";
export default function RoutesPublic(props) {
  const { restricted = false } = props;
  const { tokens } = useTokensFromStorage();
  const { user } = useUserFromStorage();
  const navigate = useNavigate();
  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    if (tokens?.[Const.TOKEN_TYPE.ACCESS] && restricted && !isEmpty(user)) {
      navigate("/dashboard");
    } else {
      setIsRender(true);
    }
  }, [navigate, restricted, tokens, user]);
  if (!isRender) {
    return null;
  }
  return <Outlet />;
}
