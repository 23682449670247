
const ContinuousProgressBar = () => (
    <div className="fixed top-0 left-0 right-0 z-50">
        <div className="h-1 bg-gray-300">
            <div
                className="h-full w-full bg-primary-brand animate-pulse">
            </div>
        </div>
    </div>
);

export default ContinuousProgressBar;