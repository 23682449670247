import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Const from "../../constants";
import usePageVisibilityChange from "../../hooks/use-page-visibility-change";
import { useTokensFromStorage, getTokenDataFromSession } from "../../contexts/token.context";
import CheckUserAccess from "../check-user-access";

import { useUserFromStorage } from "../../contexts/user.context";
import { useAsync } from "@react-org/hooks";

import * as MeApi from "../../apis/me.api";

function NoAccess() {
  return <div>You dont have access to this page</div>;
}

const defaultAccess = [...Object.values(Const.ACCESS)]; // allow all roles to access private routes by default

export default function RoutesPrivate(props) {
  const { access = defaultAccess, ...restProps } = props;
  const navigate = useNavigate();
  const { tokens } = useTokensFromStorage();
  const isVisible = usePageVisibilityChange();
  const [isRender, setIsRender] = useState(false);
  const { user, setUser } = useUserFromStorage();

  const { execute: executeGetUserInfo } = useAsync(MeApi.getUserInfo, {
    immediate: false,
  });

  useEffect(() => {
    let storageToken = tokens?.[Const.TOKEN_TYPE.ACCESS];
    let sessionToken = getTokenDataFromSession(Const.TOKEN_TYPE.ACCESS);
    if (!storageToken) {
      navigate("/");
    } else if (storageToken !== sessionToken) {
      //__TODO__: removed redirecting to user changed screen for temporary,
      //__TODO__: will add it later once the user changed screen is designed.
      // navigate("/auth/user-changed");
      setIsRender(true);
    } else {
      if (!Object.keys(user || {}).length) {
        executeGetUserInfo(
          {},
          {
            onSuccess: (res) => {
              setUser(res.data);
              setIsRender(true);
            },
          }
        );
      } else {
        setIsRender(true);
      }
    }
  }, [executeGetUserInfo, isVisible, navigate, setUser, tokens, user]);

  if (!isRender) {
    return null;
  }
  return (
    <CheckUserAccess access={access} fallback={<NoAccess />} {...restProps}>
      <Outlet />
    </CheckUserAccess>
  );
}
