import is from "./is";
export default function generateRouteConfig(_config, user) {
  let conf;
  if (is.array(_config)) {
    conf = _config.map((conf) => generateRouteConfig(conf, user)).filter((conf) => conf);
  } else {
    let { path, element: Element, elementProps = {}, container: Container, containerProps = {}, children = [], access = [], ...restConfig } = _config;
    if (access.length && !user.checkAccess(access)) {
      //Note: keep empty to remove non accessible routes
    } else {
      let element = <Element {...elementProps} />;
      if (Container) {
        element = <Container {...containerProps}>{element}</Container>;
      }
      conf = {
        element: element,
        ...restConfig,
      };
      if (path) {
        conf.path = path;
      }
      if (children.length) {
        conf.children = children.map((conf) => generateRouteConfig(conf, user)).filter((conf) => conf);
      }
    }
  }
  return conf;
}
