import React from "react";
import PropTypes from "prop-types";
import "./index.css";

import { useButtonClass } from "./styles";
import cx from "../../utils/class-names";

const COLORS = ["default", "primary", "secondary", "success", "info", "warning", "danger"];
const VARIANTS = ["outline", "link", "solid"];
const SIZES = ["xs", "sm", "md", "lg", "xl", "2xl"];

const Button = React.forwardRef(function Button(props, ref) {
  const { className = "", variant, rounded, size, color, disabled, icon = null, children, dataTitle, ...restProps } = props;

  const classes = useButtonClass({
    disabled,
    variant,
    rounded,
    size,
    color,
    className,
    icon,
  });

  return (
    <button ref={ref} data-title={dataTitle} disabled={disabled} className={cx("button", classes)} type="button" {...restProps}>
      {icon}
      <span className={cx(icon && "ml-1.5 inline-flex")}>{children}</span>
    </button>
  );
});

Button.defaultProps = {
  variant: "solid",
  size: "md",
  color: "default",
  disabled: false,
};

Button.propTypes = {
  variant: PropTypes.oneOf(VARIANTS),
  size: PropTypes.oneOf(SIZES),
  color: PropTypes.oneOf(COLORS),
  disabled: PropTypes.bool,
};

export default Button;
