import Button from "../button";
import PropTypes from "prop-types";

//__TODO__: style the error component based on the design
export default function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
  const handleClick = () => {
    resetErrorBoundary ? resetErrorBoundary() : window.location.reload();
  };

  return (
    <div role="alert" className="error-boundary-fallback p-4 bg-secondary-50 h-full w-full text-sm">
      <div className="text-xl font-bold">
        Technical Error:{" "}
        <Button color="primary" variant="link" onClick={handleClick} className="px-2">
          Try again
        </Button>
      </div>
      <pre className="text-red-500 bg-red-50 p-4">{error.message}</pre>
      <div className="my-2">
        Please try again later or check with <span className="uppercase text-primary-text font-bold">NAVGATH</span> Support Team.{" "}
      </div>
    </div>
  );
}

ErrorBoundaryFallback.defaultProps = {
  error: {},
};

ErrorBoundaryFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  resetErrorBoundary: PropTypes.func,
};
