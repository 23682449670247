import { withConfig, getApi } from "./index";
const api = getApi("auth");

export const login = withConfig((data, conf) => {
  const { username, password, ...payload } = data;
  const config = conf({
    url: "/login",
    method: "POST",
    auth: {
      username,
      password,
    },
    data: payload,
  });
  return api(config);
});
export const clientLogin = withConfig((data, conf) => {
  const { username, password, audit_type, on_behalf_of, ...payload } = data;
  const config = conf({
    url: "/login-client",
    method: "POST",
    auth: {
      username,
      password,
    },
    data: payload,
    params: { requestType: audit_type, on_behalf_of },
  });
  return api(config);
});
export const clientRegister = withConfig((data, conf) => {
  const {email_id, ...payload } = data;
  const config = conf({
    url: "/login-client",
    method: "POST",
    auth: {
      username:email_id
    },
    params:payload
  });
  return api(config);
});

export const registerBsClient = withConfig((data, conf) => {
  const { ...payload } = data;
  const config = conf({
    url: "/bs/clients/register",
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const getRegisterBsClient = withConfig((data, conf) => {
  const { companyId, ...payload } = data;
  const config = conf({
    url: `/bs/client-company/${companyId}`,
    method: "GET",
    data: payload,
  });
  return api(config);
});

export const verifyUserName = withConfig((data, conf) => {
  const { ...payload } = data;
  const config = conf({
    url: "/bs/clients/verify-username",
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const getRefreshToken = withConfig((data, conf) => {
  const { refreshToken } = data;
  const config = conf({
    url: "/access-token",
    method: "GET",
    headers: { "x-refresh-token": refreshToken },
  });
  return api(config);
});

export const getTemporaryToken = withConfig((data, conf) => {
  const config = conf({
    url: "/temporary-token",
    method: "GET",
  });
  return api(config);
});

export const validateCaptcha = withConfig((data, conf) => {
	const { ...payload } = data;
	const config = conf({
	  url: "/validate-captcha",
	  method: "POST",
	  data: payload,
	});
	return api(config);
});