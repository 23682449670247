import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ArcElement, Chart } from "chart.js";
import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { AxiosInterceptorProvider } from "./apis";
import ErrorBoundaryFallback from "./components/error-boundary-fallback";
import NotificationModal from "./components/notification-modal";
import { NotificationModalProvider } from "./components/notification-modal/provider";
import Const from "./constants";
import TokenProvider from "./contexts/token.context";
import UserProvider from "./contexts/user.context";
import "./index.css";
import RouterContainer from "./routes";

console.log({ enable: Const.SENTRY.ENABLE });

if (Const.SENTRY.ENABLE !== "false") {
  Sentry.init({
    dsn: Const.SENTRY.DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: Const.SENTRY.TRACES_SAMPLE_RATE,
    environment: Const.SENTRY.ENV,
  });
}

Chart.register(ArcElement);

const Root = (
  <>
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <BrowserRouter>
        <NotificationModalProvider>
          <NotificationModal />
          <AxiosInterceptorProvider>
            <UserProvider>
              <TokenProvider>
                <RouterContainer />
              </TokenProvider>
            </UserProvider>
          </AxiosInterceptorProvider>
        </NotificationModalProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </>
);

createRoot(document.getElementById("root")).render(Root);
