import cx from "../../utils/class-names";
import { useNotificationModalContext } from "./provider";
import NotificationIcon from "./notification-icon";
import Button from "../button";

function Modal(props) {
  const { children } = props;

  const notificationModal = useNotificationModalContext();

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={cx(
        "notification-modal w-full min-h-[256px] translate-y-full bg-white mt-auto  shadow-md flex items-center justify-center flex-col transition-transform overflow-hidden rounded-t-[100px]",
        notificationModal.open && "translate-y-0"
      )}
    >
      {children}
    </div>
  );
}

function ModalBody() {
  const { type, title, width_ = "max-w-[50%]", noCapitalize, heading, body, message, classNames = {} } = useNotificationModalContext();
  return (
    <div className={`notification-modal_body w-fit flex items-center flex-col px-4 ${width_}`}>
      <NotificationIcon type={type} />
      {title ? <p className="font-semibold my-2 font-inter text-2xl">{title}</p> : null}
      {heading ? (
        <p className={cx("whitespace-nowrap", (title && !noCapitalize) && "lowercase first-letter:capitalize", classNames?.heading)}>
          {heading}
        </p>
      ) : null}
      {body ? <p className={cx("text-xs text-secondary-700 mt-3", classNames?.body)}>{body}</p> : null}
      {message ? (
        message.split("\n").map((line, index) => (
          <p key={index} className={cx("whitespace-nowrap text-left", classNames?.message)}>
            {line}
          </p>
        ))
      ) : null}
    </div>
  );
}

function ModalFooter() {
  const { confirmText, cancelText, closeText, onConfirm, onCancel, onClose, classNames, showConfirmCTA, showCancelCTA, showCloseCTA } =
    useNotificationModalContext();

  return (
    <div className={cx("notification-modal_footer mt-[20px]", classNames?.footer)}>
      {showConfirmCTA ? (
        <>
          <Button onClick={onConfirm} color="primary" className={cx(showCancelCTA && "ml-5")}>
            {confirmText}
          </Button>
          {showCancelCTA ? (
            <Button onClick={onCancel} color="primary" variant="outline" className={cx(showCancelCTA && "ml-4")}>
              {cancelText}
            </Button>
          ) : null}
        </>
      ) : null}

      {showCloseCTA ? (
        <Button onClick={onClose} color="primary" variant="outline">
          {closeText}
        </Button>
      ) : null}
    </div>
  );
}

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
