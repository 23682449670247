import withLoadable from "../components/with-loadable";
import RoutesPublic from "../components/routes-public";

const IAUploadDocumentsPage = withLoadable(() => import("../pages/ism-isps-mlc/upload-documents.page"));
const TAWAUploadDocumentsPage = withLoadable(() => import("../pages/tawa/upload-documents.page"));
const SSAUploadDocumentsPage = withLoadable(() => import("../pages/ssa/upload-documents.page"));
const NotFoundPage = withLoadable(() => import("../pages/root/not-found.page"));
const SamplePage = withLoadable(() => import("../pages/root/sample.page"));
const MisReportPage = withLoadable(() => import("../pages/root/mis-report.page"));
const RPSUploadDocumentsPage = withLoadable(() => import("../pages/rps/upload-documents.page"));
const BSAUploadDocumentsPage = withLoadable(() => import("../pages/bsa/upload-documents.page"));
const PCSUploadDocumentsPage = withLoadable(() => import("../pages/pcs/upload-documents.page"));
const BSAUploadDueAuditDocumentsPage = withLoadable(() => import("../pages/bsa/upload-due-audit-documents.page"));
const IAVerifyDigitalCertificatePage = withLoadable(() => import("../pages/root/verify-digital-certificate.page"));
const ERSUploadDocumentsPage = withLoadable(() => import("../pages/ers/upload-documents.page"));

/**
 *
 * Routes which are available to everyone no matter if user is logged in or not
 */
export default function getPublicRoutes() {
  return [
    {
      element: RoutesPublic,
      children: [
        { path: "/ism-isps-mlc/upload-documents/:contractReviewId", element: IAUploadDocumentsPage },
        { path: "/ers/upload-documents/:serviceRequestId", element: ERSUploadDocumentsPage },
        { path: "/tawa/upload-documents/:contractReviewId", element: TAWAUploadDocumentsPage },
        { path: "/ssa/upload-documents/:contractReviewId", element: SSAUploadDocumentsPage },
        { path: "/rps/upload-documents/:contractReviewId", element: RPSUploadDocumentsPage },
        { path: "/bsa/upload-documents/:contractReviewId", element: BSAUploadDocumentsPage },
        { path: "/pcs/upload-documents/:contractReviewId", element: PCSUploadDocumentsPage },
        { path: "/bsa/audit-due-doc-request/:companyId/scope-category/:scopeCategory", element: BSAUploadDueAuditDocumentsPage },
        { path: "/verify-digital-certificate", element: IAVerifyDigitalCertificatePage },
        { path: "/sample", element: SamplePage },
        { path: "/mis-report", element: MisReportPage },
        { path: "*", element: NotFoundPage },
      ],
    },
  ];
}
