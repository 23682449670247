import { useUserFromStorage } from "../../contexts/user.context";
import { useMemo } from "react";

export default function CheckUserAccess(props) {
  const { children, access: _accessToCheck, all = false, invert = false, fallback = null } = props;
  const user = useUserFromStorage();

  const isHadAccess = useMemo(() => {
    let _isHadAccess = user.checkAccess(_accessToCheck, { all: all });
    if (invert) {
      _isHadAccess = !_isHadAccess;
    }

    return _isHadAccess;
  }, [_accessToCheck, all, invert, user]);

  if (!isHadAccess) return fallback;

  return children;
}
