import { useRoutes } from "react-router-dom";
import withLoadable from "../components/with-loadable";
import { useUserFromStorage } from "../contexts/user.context";
import generateRouteConfig from "../utils/generate-route-config";
import getPrivateRoutes from "./private.route";
import getProtectedRoutes from "./protected.route";
import getPublicRoutes from "./public.route";

const GlobalLayout = withLoadable(() => import("../layouts/global"));

function RouterContainer() {
  const user = useUserFromStorage();

  const privateRoutesConf = getPrivateRoutes();
  const protectedRouteConf = getProtectedRoutes();
  const publicRoutesConf = getPublicRoutes();

  let config = [
    {
      element: GlobalLayout,
      children: [...privateRoutesConf, ...protectedRouteConf, ...publicRoutesConf],
    },
  ]
    .map((conf) => generateRouteConfig(conf, user))
    .filter((conf) => conf);

  return useRoutes(config);
}

export default RouterContainer;
