//__TODO__: restructure error messages by module,
//__TODO__: move non message keys into constants
const MESSAGES = {
  SEND_TO_REVIEW_SUCCESS: "Sent for review",
  SEND_TO_REVIEW_FAILED: "Send for review failed",
  SEND_TO_REVIEW_PROGRESS: "Sending for review",

  FORM_MAPPING_SUCCESS: "Field mappings saved",
  FORM_MAPPING_FAILED: "Failed to save field mappings",
  FORM_MAPPING_PROGRESS: "Saving field mappings",

  SURVEY_SELECT_MESSAGE: "Please select survey type and category.",

  FORM_DATA_SUCCESS: "Form saved",
  FORM_DATA_PROGRESS: "Saving Form",
  FORM_DATA_FAILED: "Failed to save form",

  ACCEPT_FORM_DATA_PROGRESS: "Accepting Form",
  ACCEPT_FORM_DATA_FAILED: "Failed to Accept form",

  SYNC_OFFLINE_FAILED: "Sync to offline application failed",
  SYNC_OFFLINE_SUCCESS: "Data is now ready in desktop application for offline survey.",

  NO_FORMS: "No forms found",
  NO_FORM: "No form found",
  NO_SURVEY: "No reports found",
  NO_SUPPORTING_DOCUMENTS: "No supporting documents found",
  NO_NC_DOCUMENTS: "Atleast one document upload required",

  ADD_VISIT_PLANNING_FAILED: "Failed to save visit planning",

  REPORT_NO_GENERATED: "Report number has been generated",
  REPORT_NO_GENERATING: "Report number is being generated",
  REPORT_NO_GENERATE_FAILED: "Failed to generate report number",

  ACTUAL_AUDIT_PLACE_UPDATE: "Actual Audit Place has been updated",
  ACTUAL_AUDIT_PLACE_UPDATING: "Actual Audit Place is being updated",
  ACTUAL_AUDIT_PLACE_UPDATE_FAILED: "Failed to update Actual Audit Place",

  SEND_TO_DGS_PROGRESS: "Sending to DGS",
  SEND_SUCCESS: "Sent",
  SEND_DGS_SUCCESS: "Documents Dispatched to DGS",
  SEND_DGS_FAILED: "Sending to DGS failed",

  SEND_AUTH_PROGRESS: "Sending Authorisation Letter",
  SEND_AUTH_FAILED: "Sending authorisation letter failed",
  SEND_AUTH_SUCCESS: "Authorisation letter sent",

  SAVE_AUTH_LETTER_PROGRESS: "Saving authorisation letter",
  SAVE_AUTH_LETTER_SUCCESS: "Authorisation letter saved",
  SAVE_AUTH_LETTER_FAILED: "Failed to save authorisation letter",

  UPDATE_UPLOAD_EMAIL_PROGRESS: "Updating uploaded email",
  UPDATE_UPLOAD_EMAIL_SUCCESS: "Upload email updated",
  UPDATE_UPLOAD_EMAIL_FAILED: "Failed to update upload email",

  SAVE_UPLOAD_EMAIL_PROGRESS: "Saving upload email",
  SAVE_UPLOAD_EMAIL_SUCCESS: "Upload email saved",
  SAVE_UPLOAD_EMAIL_FAILED: "Failed to save upload email",

  UPDATE_PREPARE_AGREEMENT_PROGRESS: "Updating Agreement",
  UPDATE_PREPARE_AGREEMENT_SUCCESS: "Agreement Updated",
  UPDATE_PREPARE_AGREEMENT_FAILED: "Failed to update Agreement",

  SAVE_PREPARE_AGREEMENT_PROGRESS: "Saving Agreement",
  SAVE_PREPARE_AGREEMENT_SUCCESS: "Agreement saved",
  SAVE_PREPARE_AGREEMENT_FAILED: "Failed to save Agreement",

  SEND_DOC_REQ_PROGRESS: "Sending Document Request.",
  SEND_DOC_REQ_SUCCESS: "Document Request Sent",
  SEND_DOC_REQ_FAILED: "Document Request Failed",

  SIGN_IN_PROGRESS: "Signing in",
  SIGN_IN_WRONG_DATA: "	The username or password you entered is incorrect",
  SIGN_IN_FAILED: "Bad Request. Please try again later.",
  REGISTER_PROGRESS: "Registering",
  REGISTER_SUCCESS: "Registered",
  REGISTER_FAILED: "Failed to Register. Please try again later.",

  RETURN_PROGRESS: "Returning for amendment",
  RETURN_SUCCESS: "Returned for amendment",
  RETURN_ERROR: "Returning for amendment failed",

  DELETE_PROGRESS: "Deleting internal auditors",
  DELETE_SUCCESS: "Internal auditor deleted",
  DELETE_ERROR: "Failed to delete internal auditor",

  SAVE_VISIT_PROGRESS: "Saving visit details",
  SAVE_VISIT_SUCCESS: "Visit details saved",
  SAVE_VISIT_ERROR: "Failed to save visit details",

  UPDATE_EVENT_PROGRESS: "Updating event",
  UPDATE_EVENT_SUCCESS: "Event updated",
  UPDATE_EVENT_ERROR: "Failed to update",

  ADD_EVENT_PROGRESS: "Adding event",
  ADD_EVENT_SUCCESS: "Event added",
  ADD_EVENT_ERROR: "Failed to add event",

  SAVE_EVENT_PROGRESS: "Saving event",
  SAVE_EVENT_SUCCESS: "Event saved",
  SAVE_EVENT_ERROR: "Failed to save event",

  REMOVE_EVENT_PROGRESS: "Removing event",
  REMOVE_EVENT_SUCCESS: "Event removed",
  REMOVE_EVENT_ERROR: "Failed to remove event",

  SAVE_AUDITS_PROGRESS: "Saving audit details",
  SAVE_AUDITS_SUCCESS: "Audit details saved",
  SAVE_AUDITS_ERROR: "Saving audit details failed",
  SAVE: "Save",

  UPDATE_AUDITS_SUCCESS: "Audit details updated",
  UPDATE_SURVEY_PROGRESS: "Updating survey status report",
  UPDATE_SURVEY_SUCCESS: "Survey status report updated",
  UPDATE_SURVEY_ERROR: "Failed to update survey status report",

  NC_SUBMITION_PROGRESS: "Submitting NC closure request report",
  NC_SUBMITED_SUCCESS: "NC closure request report submitted",
  NC_SUBMITION_ERROR: "Failed to submit NC closure request report",

  DOWNLOAD_PROGRESS: "Downloading",
  DOWNLOAD_SUCCESS: "Downloaded successfully",
  DOWNLOAD_FAILED: "Failed to download",

  PREVIEW_SUCCESS: "Preview success",
  PREVIEW_PROGRESS: "Generating preview",
  PREVIEW_FAILED: "Failed to preview",

  PLEASE_SELECT_AUDITOR: "Please select auditor",

  ASSIGNED_AUDITOR_SUCCESS: "Auditor assigned",
  ASSIGNED_AUDITOR_PROGRESS: "Assigning auditor",
  ASSIGN_AUDITOR_FAILED: "Failed to assign auditor",

  REPORT_NUMBER_NOT_GENERATED: "Report number",

  NO_RECORDS_FOUND_FOR_SEARCH: "No data found. Please try changing the search criteria.",

  NO_RECORDS_FOUND: "No records found.",
  NO_RECORDS_FOUND_FOR_FORM_BUILDER: "No forms found.",

  SAVING_CONTRACT_REVIEW_PROGRESS: "Saving Contract Review",
  SAVING_CONTRACT_REVIEW_SUCCESS: "Contract Review saved",
  SAVING_CONTRACT_REVIEW_FAILED: "Failed to save Contract Review",
  SAVING_CONTRACT_REVIEW_FAILED_CHECK_DEFECIENCIES: "No FSI/PSC Detention deficiencies found",

  AMEND_CONTRACT_REVIEW_PROGRESS: "Amending Contract Review",
  AMEND_CONTRACT_REVIEW_SUCCESS: "Contract Review amended",
  AMEND_CONTRACT_REVIEW_FAILED: "Failed to amend Contract Review",

  AMEND_SURVEY_REQUEST_PROGRESS: "Amending survey request",
  AMEND_SURVEY_REQUEST_SUCCESS: "Survey request amended",
  AMEND_SURVEY_REQUEST_FAILED: "Failed to amend survey request",

  REASSIGN_SS_PROGRESS: "Reassigning Survey Station",
  REASSIGN_SS_SUCCESS: "Survey Station Reassigned",
  REASSIGN_SS_FAILED: "Failed to reassign Survey Station",

  INITIALTE_DOCUMENT_REVIEW_INPROGRESS: "Initiating document review",
  INITIALTE_DOCUMENT_REVIEW_SUCCESS: "Document review initiated",

  UPDATE_CONTRACT_REVIEW_PROGRESS: "Updating Contract Review",

  UPLOAD_DOC_PROGRESS: "Uploading document",
  UPLOAD_DOC_SUCCESS: "Document uploaded",
  UPLOAD_DOC_FAILED: "Document uploading failed",
  UPLOAD_DOC_FILE_SIZE_ERROR: "Please upload file less than 12 MB",
  UPLOAD_DOC_HO_FILE_SIZE_ERROR: "Please upload file less than 30 MB",
  NO_FILES_TO_UPLOAD: "File is not selected to upload",

  DELETE_DOC_CONFIRMATION: "The document will be deleted and cannot be retrieved",
  DELETE_DOC_PROGRESS: "Deleting document",
  DELETE_DOC_SUCCESS: "Document deleted",
  DELETE_DOC_FAILED: "Failed to delete document",
  DELETE_DOC_WARNING_AUTH_PERSON: "You are not authorized to delete document",

  SAVE_DOC_PROGRESS: "Adding document",
  SAVE_DOC_SUCCESS: "Document added",
  SAVE_DOC_FAILED: "Failed to save document",

  APPROVE_PROGRESS: "Approving",
  APPROVE_SUCCESS: "Approved",
  APPROVE_FAILED: "Failed to approve",

  AUTHORIZE_PROGRESS: "Authorizing",
  AUTHORIZE_SUCCESS: "Audit has been completed",
  AUTHORIZE_ERROR: "Failed to authorize",

  REPORT_PICK_CONFIRMATION: "Do you want to pick up report for review?",
  REPORT_PICKING_UP_REVIEW: "Report picking up for review",
  REPORT_PICKED_UP_REVIEW_SUCCESS: "Report picked for review",
  REPORT_PICKED_UP_FAIL: "Failed in picking up report",

  BSA_REACTIVE_COMPANY_CONFIRMATION: "Do you want to deactivate company?",
  BSA_ACTIVE_COMPANY_CONFIRMATION: "Do you want to activate company?",
  BSA_COMPANY_STATUS_PROGRESS: "Updating company details",
  BSA_COMPANY_ACTIVATED: "Company activated",
  BSA_COMPANY_REACTIVATED: "Company deactivated",
  BSA_COMPANY_ACTIVATION_FAILED: "Failed to update company status",

  DEACTIVATE_CERTIFICATE: "Certificate deactivated",
  DEACTIVATE_CERTIFICATE_FAILED: "Failed to deactivate certificate",
  DEACTIVATE_CERTIFICATE_INPROGRESS: "Deactivating Certificate",
  DEACTIVATE_CERTIFICATE_CONFIRMATION: "Do you want to deactivate certificate?",

  SRF_AMENDMEND_CONFIRMATION: "Do you want to amend the SRF?",

  REPORT_DISMISS_CONFIRMATION: "Do you want to dismiss report from review?",
  REPORT_DISMISSING_REVIEW: "Report Dismissing from review",
  REPORT_DISMISSED_SUCCESS: "Report Dissmissed from review",
  REPORT_DISMISSED_FAILED: "Failed to Dismissed",

  UPDATE_REMARK_PROGRESS: "Updating remark",
  UPDATE_REMARK_SUCCESS: "Remark updated",
  UPDATE_REMARK_ERROR: "Failed to update remark",

  REMARK_PROGRESS: "Adding remark",
  REMARK_SUCCESS: "Remark added",
  REMARK_ERROR: "Failed to add remark",

  REMOVE_REMARK_PROGRESS: "Removing remark",
  REMOVE_REMARK_SUCCESS: "Remark removed",
  REMOVE_REMARK_ERROR: "Failed to remove remark",

  FORM_DATA_SUBMIT_SUCCESS: "Data saved",
  FORM_DATA_SUBMIT_PROGRESS: "Saving form data",
  FORM_DATA_EMAIL_SENT_SUCCESS: "Mail sent successfully",

  NEW_COMPANY_DETAIL_SAVED: "New company details saved",
  NEW_COMPANY_DETAIL_PROGRESS: "Saving company details",
  FAILED_TO_SAVE_NEW_COMPANY_DETAILS: "Failed to save new company details",
  FAILED_TO_ACTIVE_NEW_CLIENT_DETAILS: "Failed to active new client details",
  ACTIVE_NEW_CLIENT_DETAILS: "Active Client Details",


  FAILED_TO_SAVE_NEW_COMPANY_DETAILS_CONFLICT: "Company with the same LRF number already exists. Please check",
  SAVING_NEW_COMPANY_DETAIL: "Saving new company details",
  USERNAME_EMAIL_ERROR_MESSAGE: "Name, Email or Username should be unique",

  EDITED_COMPANY_DETAIL: "Company details edited",
  FAILED_TO_EDIT_COMPANY_DETAILS: "Failed to edit company details",
  EDITING_COMPANY_DETAIL: "Editing company details",

  SENDING_EMAIL: "Sending Email",
  EMAIL_SEND_SUCCESS: "Email sended",
  FAILED_TO_SEND_EMAIL: "Failed to send email",

  NEW_BRANCH_DETAIL_SAVED: "New Branch saved",
  FAILED_TO_SAVE_NEW_BRANCH: "Failed to save new branch",
  SAVING_NEW_BRANCH: "Saving new branch",

  DELETING_SUB_FORM_PROGRESS: "Deleting form",
  SUB_FORM_DELETE_FAILED: "Failed to delete form",

  FORM_DATA_SUBMIT_FAILED: "Failed to save form data",

  DELETE_EVENT_PROGRESS: "Deleting event",
  DELETE_EVENT_SUCCESS: "Event deleted",
  DELETE_EVENT_ERROR: "Failed to delete event",

  THIS_FIELD_IS_REQUIRED: "This field is required",

  FORM_SUCCESSFULLY_RESET: "Successfully reset",

  SURVEY_VISIT_LIMIT_WARNING: "You enter more minutes then actual",

  ARE_YOU_SURE: "Are you sure?",
  ARE_YOU_SURE_FOR_CREATE_REQUEST: "Are you sure you want to create request?",

  ARE_YOU_SURE_YOU_WANT_TO_SEND_SUPPLEMENTS_AND_CERTIFICATES_FOR_REVIEW: "Are you sure you want to send certificates and supplements for review?",
  ARE_YOU_SURE_YOU_WANT_TO_SEND_FOR_REVIEW: "Are you sure you want to send for review?",

  REPORT_NUMBER_FOR_AUDIT_GENERATED: "Report Number for this audit will be generated",

  YOU_MAY_WILL_LOSE_YOUR_DATA: "The changes that you made may not be saved",
  YOU_MAY_WILL_LOSE_YOUR_DATA_ON_CANCEL: "You may lose your data if you cancel",

  ASSIGN_TEAM_LEADER_TO_ANOTHER_TEAM_LEADER: "You are changing the Team Leader, Existing Team Leader role will default to Team Member",
  CANCEL: "Cancel",
  YES: "Yes",
  OK: "Ok",
  NO: "No",

  NO_DOCUMENT_AVAILABLE_REQUEST: "No documents were attached/uploaded during audit/survey request.",
  NO_DOCUMENT_AVAILABLE_SURVEY: "No documents were attached/uploaded during audit/survey.",
  NO_DOCUMENT_AVAILABLE_CLIENT: "No documents were attached/uploaded during client review.",
  NO_DOCUMENT_AVAILABLE_DGS: "No documents were attached/uploaded during report authorization.",
  NO_DOCUMENT_AVAILABLE_CERTIFICATE: "No documents were attached/uploaded during Prepare FT, Amend and Extend.",
  NO_DOCUMENT_AVAILABLE_DOC_REVIEW: "No documents were attached/uploaded during audit request review.",

  DO_YOU_WANT_TO_LEAVE: "Do you want to leave the page?",

  AUTHORISATION_LETTER_SENT_TO_DGS: "Authorisation letter sent to DGS",
  FAILED_TO_SEND_AUTHORISATION_LETTER: "Failed to send authorisation letter",

  SAVE_DOCUMENT_PROGRESS: "Adding document",
  SAVE_DOCUMENT_FAILED: "Adding document failed",
  SAVE_DOCUMENT_SUCCESS: "Document added",
  DOCUMENT_EXTRACTING: "Document Extracting",
  DOCUMENT_EXTRACTED: "Document Extracted",
  FAILED_DOCUMENT_EXTRACT: "Failed to Extract Document",

  DELETE_DOCUMENT_PROGRESS: "Removing document",
  DELETE_DOCUMENT_SUCCESS: "Document removed",
  DELETE_DOCUMENT_CONFIRMATION: "Do you want to remove this document?",
  DELETE_DOCUMENT_FAILED: "Failed to remove document",

  REJECT_CONTRACT_REVIEW_MESSAGE: "Contract Review will be rejected",
  REJECT_CONTRACT_REVIEW_PROGRESS: "Rejecting Contract Review",
  REJECT_CONTRACT_REVIEW_FAILED: "Failed to reject Contract Review",
  REJECT_CONTRACT_REVIEW_SUCCESS: "Contract Review rejected",

  ONHOLD_CONTRACT_REVIEW_MESSAGE: "Contract Review will be on hold",
  ONHOLD_CONTRACT_REVIEW_PROGRESS: "Placing Contract Review on hold",
  ONHOLD_CONTRACT_REVIEW_FAILED: "Failed to place Contract Review on hold",
  ONHOLD_CONTRACT_REVIEW_SUCCESS: "Contract Review On hold",

  CUSTOMER_REQUEST_CREATED: "Customer request created",
  CUSTOMER_REQUEST_SAVED: "Customer request Saved",
  CUSTOMER_REQUEST_SUBMITTED: "Customer request submitted",
  CUSTOMER_REQUEST_UPDATEDED: "Customer request updated",

  CUSTOMER_REQUEST_WILL_BE_CANCELLED: "Customer request will be cancelled",
  CANCELLED_CUSTOMER_REQUEST_PROGRESS: "Cancelling Customer Request",
  CANCELLED_CUSTOMER_REQUEST_SUCCESS: "Customer Request cancelled",
  CANCELLED_CUSTOMER_REQUEST_FAILED: "Cancelling Customer Request failed",

  REJECT_CUSTOMER_REQUEST_MESSAGE: "Customer Request will be rejected",
  REJECT_CUSTOMER_REQUEST_PROGRESS: "Rejecting Customer Request",
  REJECT_CUSTOMER_REQUEST_FAILED: "Failed to reject Customer Request",
  REJECT_CUSTOMER_REQUEST_SUCCESS: "Customer Request rejected",

  RETURN_CUSTOMER_REQUEST_PROGRESS: "Returning Survey Request",
  RETURN_CUSTOMER_REQUEST_FAILED: "Failed to return Survey Request",
  RETURN_CUSTOMER_REQUEST_SUCCESS: "Survey Request returned",

  CONTRACT_REVIEW_ACCEPTED: "Contract Review accepted",
  CONTRACT_REVIEW_CLIENT_SUBMITTED: "Your request has been submitted. We will get back to you at the earliest",
  CONTRACT_REVIEW_SAVED: "Contract Review saved",
  CONTRACT_REVIEW_UPDATED: "Contract Review updated",
  REQUEST_WILL_BE_SAVED_ALERT: "Request will be saved",
  ACCEPT_AMENDMENT_REQUEST: "You want to accept amendment request audit?",
  CLIENT_REQUEST_SUBMITTED: "Request has been submitted",

  AUDIT_ALREADY_ACCEPTED_ALERT: "You cannot make any changes as this audit is already “Accepted”.",
  AUDIT_REPORT_NO_GENERATED_ALERT: "You cannot make any changes as the Survey Report Number has generated.",
  LAST_MARK_DATE_ALERT: "You cannot make any changes as Last mark date was done.",

  REQUIRED_FIELD: "This field is required.",

  THIS_FIELD_SHOULD_GRETER_THAN: "This field should be greater than ",

  AMEND_AUTH_PROGRESS: "Sending amended authorisation letter",
  AMEND_AUTH_SUCCESS: "Amended authorisation letter sent",
  AMEND_AUTH_FAILED: "Failed to send amended authorisation letter",

  OLD_AUTH_LETTER_DELETING: "On Update, existing Authorization letter will be regenerated!",
  BSA_OLD_AUTH_LETTER_DELETING: "On Updating the Contract Review, existing Authorization letter will be regenerated!",
  DO_YOU_WANT_TO_REMOVE_THIS_AUDIT_DETAILS: "Do you want to remove this visit schedule entry?",
  DO_YOU_WANT_TO_REMOVE_THIS_VISIT_DETAIL: "Do you want to remove this visit detail?",
  DO_YOU_WANT_TO_REMOVE_THIS_SURVEYS: "Do you want to remove this Surveys?",
  DO_YOU_WANT_TO_REMOVE_THIS_SURVEY: "Do you want to remove this Survey?",

  SAVING_AUDITORS_DETAILS: "Saving auditor details",
  AUDITORS_DETAILS_SAVED: "Auditor details saved",

  SAVING_BLOCK_FEE_DETAILS: "Saving block fee details",
  BLOCK_FEE_DETAILS_SAVED: "Block fee details saved",

  DO_YOU_WANT_TO_REMOVE_THIS_BLOCK_FEE_DETAILS: "Do you want to remove this block fee details?",
  DO_YOU_WANT_TO_REMOVE_THIS_AUDITOR_DETAILS: "Do you want to remove this auditor details?",
  DO_YOU_WANT_TO_REMOVE_THIS_DOCUMENT: "Do you want to remove this document?",

  SUBMITING_AUDITS_PROGRESS: "Saving audit visit schedule details",
  SUBMIT_AUDITS_SUCCESS: "Audit visit schedule details saved",

  SUBMITING_VISIT_DETAILS_PROGRESS: "Saving visit schedule details",
  SAVING_VISIT_DETAILS: "Saving Visit details",
  SUBMIT_VISIT_DETAILS_SUCCESS: "Visit details saved",

  THIS_FIELD_SHOULD_BE_PAST_DATE: "This field should be a past date",

  KINDLY_REFINE_THE_SEARCH_CRITERIA: "Kindly refine the search criteria",

  CONTRACT_REVIEW_WILL_BE_CANCELLED: "Contract Review will be cancelled",
  CANCELLED_CONTRACT_REVIEW_PROGRESS: "Cancelling Contract Review",
  CANCELLED_CONTRACT_REVIEW_SUCCESS: "Contract Review cancelled",
  CANCELLED_CONTRACT_REVIEW_FAILED: "Cancelling Contract Review failed",

  HO_EXAMINER_ASSIGNED: "HO Examiner has been successfully assigned.",
  HO_EXAMINER_REASSIGNED: "HO Examiner reassigned",
  HO_EXAMINER_REASSIGNING: "HO Examiner reassigning",

  PROJECT_REMARKS_ADDED: "Project remarks added",
  DELETE_REPORT_REMARK: "This remark will be deleted",

  DR_CHECKLIST_REMARKS: "Audit will return back to surveyor with remarks",
  DR_CHECKLIST_REMARKS_PROGRESS: "Adding remarks to document checklist",
  DR_CHECKLIST_REMARKS_FAILED: "Failed to Add remarks",
  DR_CHECKLIST_REMARKS_SUCCESS: "Added Remarks",

  SUBMIT_VISIT_DETAILS_FAILED: "Failed to save visit schedule details",

  BLOCK_FEE_DETAILS_SAVED_FAILED: "Failed to save block fee details",

  BLOCK_FEE_DETAILS_DELETE_PROGRESS: "Deleting block fee details",
  BLOCK_FEE_DETAILS_DELETED: "Deleted block fee detail",

  VISITED_DETAILS_DELETED_PROGRESS: "Deleting visit details",
  VISITED_DETAILS_DELETED_SUCCESS: "Deleted visit details",
  VISITED_DETAILS_FIRST_VISIT_WARNING: "We cannot delete First visit date",

  CONTRACT_REVIEW_ALREADY_ACCEPTED_ALERT: `You cannot make any changes as this Contract Review is 'Accepted'`,
  CONTRACT_REVIEW_ALREADY_REJECTED_ALERT: `You cannot make any changes as this Contract Review is 'Rejected'`,
  CONTRACT_REVIEW_ALREADY_PENDING_ALERT: `You cannot make any changes as this Contract Review is 'On Hold'`,
  CONTRACT_REVIEW_ASSIGNEE_NOT_AVAILABLE_ALERT: "You cannot make any changes as the examiner is not assigned yet",

  AUDITORS_DETAILS_DELETING: "Deleting auditor details",
  AUDITORS_DETAILS_DELETING_SUCESSS: "Auditor details deleted",
  AUDITORS_DETAILS_DELETE_FAILED: "Failed to delete auditor details",
  AUDITOR_REASSIGNED_SUCCESS: "Auditor reassigned",

  BLOCK_FEE_DELETED_FAILED: "Failed to delete block dee details",

  AUDIT_VISIT_DELETE_PROGRESS: "Deleting audit visit details",
  AUDIT_VISIT_DELETE_SUCCESS: "Deleted audit details",
  AUDIT_VISIT_DELETE_FAILED: "Failed to delete audit visit details",

  ADD_AUDITORS_FAILED: "At least one team leader must be assigned",
  DUPLICATE_VISIT_DATE_ERROR: "Duplicate visit-dates not allowed",
  INLUCDE_MONITOR: "Please include Monitor in this audit",
  CHECK_ACTIVITY_MONITORING: "Please check value for Activity Monitoring",
  LIMIT_FOR_TRAINEE: "Upto 2 trainees can be assigned",
  ADD_BLOCK_FEE_FAILED: "Failed to add block fee details",
  ADD_VISIT_SCHEDULE_FAILED: "Failed to add audit visit details",
  OVERDUE_ERROR_MESSAGE_FOR_INTERIM: "Please select surveyor without overdue.",

  CONTRACT_REVIEW_ON_HOLD: "Contract Review marked 'On Hold'",
  CONTRACT_REVIEW_ON_HOLD_PROGRESS: "Marking Contract Review 'On Hold'",
  CONTRACT_REVIEW_ACCEPTED_PROGRESS: "Accepting Contract Review",
  CONTRACT_REVIEW_SAVED_PROGRESS: "Saving Contract Review",

  PLEASE_FILL_THE_SURVEY_TYPE: "Please fill the survey type",

  SEND_FOR_REVIEW_PROGRESS: "Sending for review",
  SEND_FOR_REVIEW_SUCCESS: "Sent for review",
  SEND_SUPP_CERTS_FOR_REVIEW_SUCCESS: "Certificates and Supplements are sent for review",
  SEND_FOR_REVIEW_FAILD: "Failed to sent for review",

  FORM_SUBMITTED: "Form submitted",
  FORM_COMPLETED: "Form completed",
  SUB_FORM_DELETED: "Form deleted",

  ONCE_THE_FORM_IS_SUBMITTED_IT_CANNOT_BE_EDITED_LATER: "Once the form is submitted, it cannot be edited later.",

  CONFIRM_REQUEST_DOCUMENT: "Are you sure you want to request these documents",

  NO_DETENTION_FOUND: "No Detention Details Found",
  NO_DEFICIENCES_FOUND: "No Deficiencies Found",

  VALIDATION_STRING_MSG: "The value must be string",
  VALIDATION_DATE_MSG: "The value must be date",
  VALIDATION_NUMBER_MSG: "The value must be numeric",
  VALIDATION_CHARACTER_MSG: "The value must be character",
  VALIDATION_BOOL_MSG: "The value must be bool",
  VALIDATION_DUPLICATE_VAL: "Duplicate values are not allowed",

  MARKING_LAST_DAY_OF_VISIT_PROGRESS: "Marking last day of visit",
  MARKING_LAST_DAY_OF_VISIT_SUCCESS: "Marked last day of visit",
  UNMARK_VISIT_SUCCESS: "Unmarked last day of visit",
  UNMARK_VISIT_PROGRESS: "Unmarking last day of visit",
  UNMARK_VISIT_FAILED: "Failed to unmark last day of visit",
  MARKING_LAST_DAY_OF_VISIT_FAILED: "Failed to marking last day of visit",
  MARKING_LAST_DAY_OF_VISIT_CONFIRMATION: "You need to mark last visit date of audit",
  UNMARKING_LAST_DAY_OF_VISIT_CONFIRMATION: "You need to unmark last visit date of audit",
  SEND_FOR_REVIEW_CONFIRMATION: "Supporting documents are not uploaded as per IOM. Do you still want to Send for Review?",
  SEND_FOR_REVIEW_CONFIRMATION_PCS: "Your reports are being submitted for review and you will be not able to make additional modifications.",
  SEND_FOR_REVIEW_CONFIRMATION_RPS: "Your reports are being submitted for review and you will be not able to make additional modifications.",
  SAVE_VISIT_WARNING: "Please Save the Visit Details",

  SAVE_SCHEDULE_WARNING: "Please click on Schedule and save the form",
  QUALIFIED_AUDITOR: "Please select the qualified auditor only as Team Leader",

  SESSION_EXPIRED: {
    TITLE: "Session Expired.",
    HEADING: "You will be redirected to the Login page.",
  },

  ACCESS_DENIED_GENERATED_REPORT_NO: "Only allocated team members are permitted to generate report numbers",

  ACCESS_DENIED: {
    TITLE: "You are unauthorized to perform this action",
    HEADING: (
      <>
        You do not have permission to access this resource. Please check with <span className="uppercase">NAVGATH</span> Support Team.
      </>
    ),
  },

  TECHNICAL_ERROR: {
    TITLE: "Technical Error",
    HEADING: (
      <>
        Please try again later or check with <span className="uppercase">NAVGATH</span> Support Team.
      </>
    ),
  },
  APPROVE_REPORT_REVIEW_PROGRESS: "Approving for Report Review",
  APPROVE_REPORT_REVIEW_SUCCESS: "Report Review Approved",
  APPROVE_REPORT_REVIEW_FAILED: "Failed to Approve Report Review",

  APPROVE_AUDIT_REVIEW_PROGRESS: "Approving for Audit Report",
  APPROVE_AUDIT_REVIEW_SUCCESS: "Audit Review Approved",
  APPROVE_AUDIT_REVIEW_FAILED: "Failed to Approve Audit Report",

  TAWA_CLIENT_REQUEST_FORM_SUCCESS: "Your request has been submitted.  We will get back to you at the earliest.",

  AMEND_CERTIFICATE_PROGRESS: "Amending Certificate",
  AMEND_CERTIFICATE_SUCCESS: "Certificate is Amended",
  AMEND_CERTIFICATE_FAILED: "Failed to Amend Certificate",

  UPDATE_CERTIFICATE_PROGRESS: "Certificate is Updating",
  UPDATE_CERTIFICATE_SUCCESS: "Certificate is Updated",
  UPDATE_CERTIFICATE_FAILED: "Failed to Update Certificate",

  GENERATE_FT_CERTIFICATE_PROGRESS: "Generating Full-Term Certificate...",
  GENERATE_FT_CERTIFICATE: "Do you want to generate the Full-Term Certificate",
  GENERATE_FT_CERTIFICATE_SUCCESS: "Full-Term Certificate generated successfully.",
  GENERATE_FT_CERTIFICATE_FAILED: "Failed to generate the Full-Term Certificate.",

  UPDATE_FT_CERTIFICATE_PROGRESS: "Updating Full-Term Certificate...",
  UPDATE_FT_CERTIFICATE: "Do you want to Update the Full-Term Certificate",
  UPDATE_FT_CERTIFICATE_SUCCESS: "Full-Term Certificate Updated successfully.",
  UPDATE_FT_CERTIFICATE_FAILED: "Failed to Update the Full-Term Certificate.",

  ADD_CERTIFICATE_PROGRESS: "Certificate is Adding",
  ADD_CERTIFICATE_SUCCESS: "Certificate is Added",
  ADD_CERTIFICATE_FAILED: "Failed to Add Certificate",

  INVALIDATE_CERTIFICATE_CONFIRMATION: "The certificate will be invalidate and cannot be retrieved.",
  INVALIDATE_CERTIFICATE_PROGRESS: "Certificate is Invalidating.",
  INVALIDATE_CERTIFICATE_SUCCESS: "Certificate is Invalidated.",
  INVALIDATE_CERTIFICATE_FAILED: "Failed to Invalidate Certificate.",

  ISSUE_DUPLICATE_CERT_PROGRESS: "Issuing Duplicate Certificate",
  ISSUE_DUPLICATE_CERT_FAILED: "Failed to Issue Duplicate Certificate",
  ISSUE_DUPLICATE_CERT_SUCCESS: "Duplicate Certificate is Issued",

  ISSUE_CERT_PROGRESS: "Issuing Certificate",
  ISSUE_CERT_FAILED: "Failed to Issue Certificate",
  ISSUE_CERT_SUCCESS: "Certificate is Issued",

  PREPARE_CERT_PROGRESS: "Preparing Certificate",
  PREPARE_CERT_FAILED: "Failed to Prepare Certificate",
  PREPARE_CERT_SUCCESS: "Certificate Prepared",

  ACITIVITY_MONITORING_SAVE_PROGRESS: "Saving Activity Monitoring Details",
  ACITIVITY_MONITORING_SAVE_SUCCESS: "Activity Monitoring Details Saved",
  ACITIVITY_MONITORING_SAVE_FAILED: "Saving of Activity Monitoring Failed",

  ACITIVITY_MONITORING_SUBMIT_PROGRESS: "Submitting Activity Monitoring Details",
  ACITIVITY_MONITORING_SUBMIT_SUCCESS: "Activity Monitoring Details Submitted",
  ACITIVITY_MONITORING_SUBMIT_FAILED: "Activity Monitoring Data Submission Failed",

  GENERATE_CERTIFICATE_PROGRESS: "Certificate is being generated",
  GENERATE_CERTIFICATE_SUCCESS: "Certificate is generated",
  GENERATE_CERTIFICATE_FAILED: "Failed to generate Certificate",
  GENERATE_CERTIFICATE_WARNING: "Please add atleast one digital signature",
  GENERATE_CERTIFICATE_WARNING_2: "Please add atleast one menu item",

  RESOLVE_REMARKS: "Please go back and resolve remarks before you submit form",

  INITIATE_PROJECT_STATUS: "Are you sure you want to Initiate Project?",
  UPDATE_PROJECT_STATUS_PROGRESS: "Updating Project Status",
  UPDATE_PROJECT_STATUS_FAILED: "Failed to Update Project Status",
  UPDATE_PROJECT_STATUS_SUCCESS: "Project Status Updated",

  ACITIVITY_MONITORING_APPROVE_PROGRESS: "Approving Activity Monitoring",
  ACITIVITY_MONITORING_APPROVE_SUCCESS: "Activity Monitoring Approved",
  MMSA_APPROVE_SUCCESS: "MMSA Record completed",
  ACITIVITY_MONITORING_APPROVE_FAILED: "Activity Monitoring Approve Request Failed",
  MMSA_APPROVE_FAILED: "MMSA Record failed",

  MMSA_RETURNED_PROGRESS: "Returning MMSA Record",
  MMSA_RETURNED_SUCCESS: "MMSA Record Returned",
  MMSA_RETURNED_FAILED: "MMSA Record Returned Request Failed",
  ACITIVITY_MONITORING_RETURNED_PROGRESS: "Returning Activity Monitoring",
  ACITIVITY_MONITORING_RETURNED_SUCCESS: "Activity Monitoring Returned",
  ACITIVITY_MONITORING_RETURNED_FAILED: "Activity Monitoring Returned Request Failed",

  SURVEYOR_QUALIFICATION_APPROVE_PROGRESS: "Approving Surveyor Qualification",
  SURVEYOR_QUALIFICATION_APPROVED: "Surveyor Qualification Report Accepted",
  SURVEYOR_QUALIFICATION_FAILED: "Surveyor Qualification Report Failed to Accept ",

  ACITIVITY_MONITORING_ACCEPT_PROGRESS: "Accepting Activity Monitoring",
  ACITIVITY_MONITORING_ACCEPT_SUCCESS: "Activity Monitoring Accepted",
  ACITIVITY_MONITORING_ACCEPT_FAILED: "Activity Monitoring Accept Request Failed",

  ACITIVITY_MONITORING_PASSTORM_PROGRESS: "Sending for RM Approval",
  ACITIVITY_MONITORING_PASSTORM_SUCCESS: "Sent for RM Approval",
  ACITIVITY_MONITORING_PASSTORM_FAILED: "Failed to send for RM Approval",

  ACITIVITY_MONITORING_RETURN_PROGRESS: "Returning Activity Monitoring",
  ACITIVITY_MONITORING_RETURN_SUCCESS: "Returned Successfully",
  ACITIVITY_MONITORING_RETURN_FAILED: "Return Failed",

  ACITIVITY_MONITORING_AMEND_PROGRESS: "Amending Activity Monitoring",
  ACITIVITY_MONITORING_AMEND_SUCCESS: "Amended Successfully",
  ACITIVITY_MONITORING_AMEND_FAILED: "Amend Failed",

  ACITIVITY_MONITORING_PREVIEW_PROGRESS: "Previewing the Activity Monitoring Report",
  ACITIVITY_MONITORING_PREVIEW_FAILED: "Failed to Preview Activity Monitoring Report",

  ANNUAL_REQUEST: "Are you sure you want to request Annual Audit?",
  RENEWAL_REQUEST: "Are you sure you want to request Renewal Audit?",
  AMENDMENT_REQUEST: "Are you sure you want to request Amendment Audit?",

  REQUEST_FAILED: "Request Failed",
  REQUEST_CONFLICT: "Request has some conflicts. Please check",
  AMENDMENT_REQUEST_CONFIRMATION: "Requested for Amendment, without involving the SS",

  COMPANY_BRANCHES_ADD_SUCCESS: "Company's Branch Added",
  MANUFACTURING_UNIT_ADDED: "Manufacturing Unit added",
  COMPANY_BRANCHES_ADD_PROGRESS: "Adding Company's Branch",
  COMPANY_BRANCHES_ADD_FAILED: "Failed to Add Company's Branch",

  TAWA_REQUEST: {
    FORM_SUCCESS: "Your request has been submitted. We will get back to you at the earliest.",
  },

  LAST_VISIT_DATE_FUTURE_DATE: "The last date of the survey should not be in the future",
  FORM_SUBMIT_SUCCESS: "Form Submitted",
  FORM_SUBMIT_PROGRESS: "Submitting Form",
  FORM_SUBMIT_ERROR: "Failed to Submit Form",

  FORM_UPDATE_SUCCESS: "Form Updated",
  FORM_UPDATE_PROGRESS: "Updating Form",
  FORM_UPDATE_ERROR: "Failed to Update Form",

  AUDIT_CATEGORY_CHANGE_WARNING: "Due to change of survey type, your all uploaded documents will be moved into others document.",

  DELETE_CERTIFICATE_PROGRESS: "Deleting Digital Certificate",
  DELETE_CERTIFICATE_SUCCESS: "Digital Certificate deleted",
  DELETE_CERTIFICATE_FAILED: "Failed to delete Digital Certificate",

  SEND_DIGITAL_CERTIFICATE_PROGRESS: "Sending Digital Certificate",
  SEND_DIGITAL_CERTIFICATE_CONFIRMATION: "Digital Certificate will be sent",
  SEND_DIGITAL_CERTIFICATE_SUCCESS: "Digital Certificate Sent",
  SEND_DIGITAL_CERTIFICATE_FAILED: "Failed to send Digital Certificate",

  SEND_SRF_DOCUMENT_PROGRESS: "Sending SRF Document",
  SEND_SRF_DOCUMENT_SUCCESS: "SRF Document Sent",
  SEND_SRF_DOCUMENT_FAILED: "Failed to sending SRF Document",

  IS_DOWNLOADED_IN_DESKTOP: (report_no) => `${report_no} is downloaded in IR Navgath Desktop Application`,
  BLOCK_FEE_IN_MINUTE: (available, used) => `Available Block Fee ${available}. You entered ${used}.`,

  VALIDITY_EXCEEDS_MORE_THAN_5_YEARS: "Validity of certificate exceeds more than 5 years",

  DELETE_REPORT_NUMBER_PROGRESS: "Deleting Report Number",
  DELETE_REPORT_NUMBER_SUCCESS: "Report Number Deleted",
  DELETE_REPORT_NUMBER_FAILED: "Deleting Report Number Failed",

  SAVE_DESPATCH_LETTER_TEMPLATE_PROGRESS: "Saving Despatch letter template",
  SAVE_DESPATCH_LETTER_TEMPLATE_SUCCESS: "Despatch letter template saved",
  SAVE_DESPATCH_LETTER_TEMPLATE_FAILED: "Failed to save Despatch letter template",

  AUDIT_REMINDER_SENDING: "Sending Audit reminder",
  AUDIT_REMINDER_SENDED_SUCCESS: "Audit reminder sent",
  AUDIT_REMINDER_SENDING_FAILED: "Failed to send reminder",

  GENERATE_DIGITAL_REPORT_PROGRESS: "Digital Report is being generated",
  GENERATE_DIGITAL_REPORT_SUCCESS: "Digital Report is generated",
  GENERATE_DIGITAL_REPORT_FAILED: "Failed to generate Digital Report",
  GENERATE_DIGITAL_REPORT_WARNING: "Please add atleast one digital signature",

  PUBLISH_DIGITAL_REPORT_PROGRESS: "Digital Report is being publish",
  PUBLISH_DIGITAL_REPORT_SUCCESS: "Digital Report is published",
  PUBLISH_DIGITAL_REPORT_FAILED: "Failed to publish Digital Report",

  SRF_PROGRESS: "Saving Survey Request",
  SRF_ACCEPTED: "Survey Request accepted",
  SRF_FAILED: "Failed to save Survey Request",

  SURVEY_CATEGORIES_PROGRESS: "Saving survey categories in progress",
  SURVEY_CATEGORIES_SUCCESS: "Survey categories saved",
  SURVEY_CATEGORIES_FAILED: "Failed to save Survey Categories",
  SURVEY_CATEGORIES_ADDED_PROGRESS: "Adding survey categories in progress",
  SURVEY_CATEGORIES_ADDED_SUCCESS: "Survey categories added",
  SURVEY_CATEGORIES_ADDED_FAILED: "Failed to add Survey Categories",

  SURVEYORS_ASSIGN_SUCCESS: "Surveyors Assigned",
  SURVEYORS_ASSIGN_FAILED: "Failed to assign Surveyors",
  SURVEYORS_ASSIGN_PROGRESS: "Surveyor Assignment in progress",
  ELIGABLE_SURVEYORS_REQUIRED: "Atleast one qualified Surveyor is required",

  DELETE_SURVEYES_SUCCESS: "Surveys deleted successfully",
  DELETE_SURVEYES_FAILED: "Failed to delete Surveys",
  DELETE_SURVEYES_PROGRESS: "Deletion of Surveys in progress",

  REJECTING_SURVEYE_DELETION_REQUEST_SUCCESS: "Survey deletion request is rejected",
  REJECTING_SURVEYE_DELETION_REQUEST_FAILED: "Failed to reject survey deletion request",
  REJECTING_SURVEYE_DELETION_REQUEST_PROGRESS: "Rejecting survey deletion request in progress",

  SURVEY_TEAM_PROGRESS: "Saving survey team in progress",
  SURVEY_TEAM_SUCCESS: "Survey team saved",
  SURVEY_TEAM_FAILED: "Failed to save survey team",
  ASSIGN_TEAM_LEADER_FAILED: "Please assign New Team Leader",

  FLAG_AUTHORIZATION_REQUIRED_FOR_OVERDUE_STATUTORY: "Flag Authorization required for overdue Statutory Surveys",

  RECOMMENDATION_SAVE_PROGRESS: "Saving Survey Visit Report",
  RECOMMENDATION_SAVE_SUCCESS: "Survey Visit Report Saved",
  RECOMMENDATION_SAVE_FAILED: "Failed to save Survey Visit Report",

  RECOMMENDATION_DELETE_PROGRESS: "Deleting Survey Visit Report",
  RECOMMENDATION_DELETE_SUCCESS: "Survey Visit Report Deleted",
  RECOMMENDATION_DELETE_FAILED: "Failed to delete Survey Visit Report",

  RECOMMENDATION_DELETE_CONFIRMATION: "Are you sure you want to delete this report?",

  QS_REJECT_CONFIRMATION: "Are you sure you want to reject this qualification survey?",
  QS_REJECT_SUCCESS_CONFIRMATION: "Qualification Survey has been rejected",
  QS_APPROVAL_PROGRESS: "Approval in Progress",
  QS_APPROVAL_SUCCESS: "Qualification Survey has been approved",
  QS_APPROVAL_FAILED: "Failed to approve qualification survey",
  QS_SAVE_PROGRESS: "Saving qualification survey",
  QS_SAVE_SUCCESS: "Qualification survey saved",
  QS_SAVE_FAILED: "Failed to save qualification survey",

  MS_SAVE_PROGRESS: "Saving mentoring survey",
  MS_SAVE_SUCCESS: "Mentoring survey saved",
  MS_SAVE_FAILED: "Failed to save mentoring survey",

  REGISTRING_USER: "Registering user",
  USER_REGISTERED: "You have successfully registered.",

  FAILED_TO_SUBMIT_CAPTCHA: "Failed to submit captcha",

  REJECT_CLIENT_REGISTRATION_MESSAGE: "Client registration  will be rejected",
  REJECT_CLIENT_REGISTRATION_PROGRESS: "Rejecting Client registration",
  CLIENT_REGISTRATION_FAILED: "Failed to reject Client registration",
  REJECT_CLIENT_REGISTRATION_SUCCESS: "Client registration rejected",

  CLIENT_REGISTRATION_PROGRESS: "Saving client registration",
  CLIENT_REGISTRATION_SUCCESS: "Client Registration Accepted",
  CLIENT_REGISTRATION_FAILED_TO_ACCEPT: "Failed to accept client registration",

  CHANGE_PASSWORD_PROGRESS: "Updating password",
  CHANGE_PASSWORD_SUCCESS: "Password changed successfully",
  CHANGE_PASSWORD_FAILED: "Failed to update password Please try again",

  FORGET_PASSWORD_PROGRESS: "Forget password in progress",
  FORGET_PASSWORD_SUCCESS: "We've sent a temporary password to your email. Kindly log using new password",
  FORGET_PASSWORD_FAILED_TO_ACCEPT: "Failed to forget password",

  GENERATE_SURVEY_REPORT: "Inspection Report Saved",
  GENERATE_SURVEY_REPORT_PROGRESS: "Inspection Report Generated",
  DO_YOU_WANT_TO_CREATE_REPORT_HEADER: "Do you want to create Inspection Report?",

  CANCELLED_REPORT_HEADER_PROGRESS: "Cancelling Reporting",
  CANCELLED_REPORT_HEADER_SUCCESS: "Reporting cancelled",
  CANCELLED_REPORT_HEADER_FAILED: "Report Cancelling failed",

  CMCIP_VESSEL:
    'Vessel Identified under "Condition Monitoring" dtd 16/11/2023, Confirmatory survey is required to access the condition of vessel under condition monitoring program',

  LOADING: "Loading",

  UPDATE_REPORT_REVIEW_STATUS_PROGRESS: "Updating Report Review Status",
  UPDATE_REPORT_REVIEW_STATUS_SUCCESS: "Report Review Status updated",
  UPDATE_REPORT_REVIEW_STATUS_FAILED: "Failed to Update Report Review Status",
  BSA_DISABLED_VIEW_REPORT_TOOLTIP: "You can view the reports after picking this audit for review",
  BSA_DISABLED_REVIEW_COMPLETE: "Close the open remarks to complete the review.",

  CREATE_NOTE_FAILED: "Failed to save note",
  UPDATE_NOTE_FAILED: "Failed to update note",
  CREATE_NOTE_SUCCESS: "Note saved successfully",
  CREATE_NOTE_PROGRESS: "Saving note",
  DELETE_NOTE_FAILED: "Failed to delete note",
  DELETE_NOTE_SUCCESS: "Note deleted successfully",
  DELETE_NOTE_PROGRESS: "Deleting note",

  MARKING_LAST_DAY_OF_VISIT_SURVEY_CONFIRMATION: "You need to mark last visit date of survey",
  UNMARKING_LAST_DAY_OF_VISIT_SURVEY_CONFIRMATION: "You need to unmark last visit date of survey",
  UNMARKING_LAST_DAY_OF_VISIT_SURVEY_CONFIRMATION_AFTER_72HOURS: "Are you sure want to request RM for the approval to Revert Ship Survey Status?",

  MARK_LAST_DAY_OF_VISIT_INSPECTION_CONFIRMATION: "You need to mark last visit date of inspection",
  UNMARK_LAST_DAY_OF_VISIT_INSPECTION_CONFIRMATION: "You need to unmark last visit date of inspection",

  SAVE_SURVEYS_PROGRESS: "Saving Surveys",
  SAVE_SURVEYS_SUCCESS: "Surveys saved",
  SAVE_SURVEYS_FAILED: "Failed to save Surveys",
  IS_DELETED_IN_CASE_CSH_WARNING: "IS to be deleted in case CSH is to be marked completed.",

  SAVE_SURVEY_SCOPE_TYPES_PROGRESS: "Saving Survey Scope Types",
  SAVE_SURVEY_SCOPE_TYPES_SUCCESS: "Survey Scope Types saved",
  SAVE_SURVEY_SCOPE_TYPES_FAILED: "Failed to save Survey Scope Types",

  SAVE_CERTIFICATE_PROGRESS: "Saving Certificate",
  SAVE_CERTIFICATE_SUCCESS: "Certificate saved",
  SAVE_CERTIFICATE_FAILED: "Failed to save Certificate",

  DELETE_SURVEY_CERTIFICATE_PROGRESS: "Delete Certificate",
  DELETE_SURVEY_CERTIFICATE_SUCCESS: "Certificate deleted",
  DELETE_SURVEY_CERTIFICATE_FAILED: "Failed to delete Certificate",

  SAVE_ITEM_UPDATE_PROGRESS: "Item Update in progress",
  SAVE_ITEM_UPDATE_SUCCESS: "Item Updated",
  SAVE_ITEM_UPDATE_FAILED: "Failed to update item",

  SAVE_ADDITIONAL_INFORMATIONS_PROGRESS: "Saving Additional Information",
  SAVE_ADDITIONAL_INFORMATIONS_SUCCESS: "Additional information saved",
  SAVE_ADDITIONAL_INFORMATIONS_FAILED: "Failed to save Additional Information",

  SAVE_STATUTORY_CONDITION_PROGRESS: "Saving Statutory Condition",
  SAVE_STATUTORY_CONDITION_SUCCESS: "Statutory Condition saved",
  SAVE_STATUTORY_CONDITION_FAILED: "Failed to save Statutory Condition",

  SAVE_PCS_FSI_DEFICIENCIES_PROGRESS: "Saving Pcs Fsi Deficiencies",
  SAVE_PCS_FSI_DEFICIENCIES_SUCCESS: "Pcs Fsi Deficiencies saved",
  SAVE_PCS_FSI_DEFICIENCIES_FAILED: "Failed to save Pcs Fsi Deficiencies",

  SAVE_NEW_REQUIREMENTS_PROGRESS: "Saving New requirements",
  SAVE_NEW_REQUIREMENTS_SUCCESS: "New requirements saved",
  SAVE_NEW_REQUIREMENTS_FAILED: "Failed to save New requirements",

  SAVE_MEMORANDA_PROGRESS: "Saving Memoranda",
  SAVE_MEMORANDA_SUCCESS: "Memoranda saved",
  SAVE_MEMORANDA_FAILED: "Failed to save Memoranda",

  SAVE_CONDITION_OF_CLASS_PROGRESS: "Saving Condition of Class",
  SAVE_CONDITION_OF_CLASS_SUCCESS: "Condition of Class saved",
  SAVE_CONDITION_OF_CLASS_FAILED: "Failed to save Condition of Class",

  APPROVE_EXTENSION_REQUEST_OF_CONDITION_OF_CLASS_PROGRESS: "Approving extension request for Condition of Class",
  APPROVE_EXTENSION_REQUEST_OF_CONDITION_OF_CLASS_SUCCESS: "Condition of Class extension request approved",
  APPROVE_EXTENSION_REQUEST_OF_CONDITION_OF_CLASS_FAILED: "Failed to approve Condition of Class extension request",

  SHIP_REQUEST_SUCCESS:
    "Thank you for submitting your vessel details. You will now be logged out. Our support team will contact you soon to assist with your request.",

  SURVEY_QUALIFICATION_ACCEPT_PROGRESS: "Accepting Surveyor Qualification",
  SURVEY_QUALIFICATION_ACCEPT_SUCCESS: "Surveyor Qualification Report Accepted",
  SURVEY_QUALIFICATION_ACCEPT_FAILED: "Surveyor Qualification Accept Request Failed",

  SURVEY_QUALIFICATION_APPROVE_PROGRESS: "Approving Surveyor Qualification",
  SURVEY_QUALIFICATION_APPROVE_SUCCESS: "Surveyor Qualification Approved",
  SURVEY_QUALIFICATION_APPROVE_FAILED: "Surveyor Qualification Approve Request Failed",

  SURVEY_QUALIFICATION_PASSTORM_PROGRESS: "Sending for RM Approval",
  SURVEY_QUALIFICATION_PASSTORM_SUCCESS: "Sent for RM Approval",
  SURVEY_QUALIFICATION_PASSTORM_FAILED: "Failed to send for RM Approval",

  SURVEY_QUALIFICATION_PREVIEW_PROGRESS: "Previewing the Surveyor Qualification Report",
  SURVEY_QUALIFICATION_PREVIEW_FAILED: "Failed to Preview Surveyor Qualification Report",

  DELETE_DIGITAL_REPORT_CONFIRMATION: "The digital report will be deleted and cannot be retrieved",
  DELETE_REPORT_PROGRESS: "Deleting Digital Report",
  DELETE_DIGITAL_REPORT_SUCCESS: "Digital Report deleted",
  DELETE_DIGITAL_REPORT_FAILED: "Failed to delete Digital Report",

  THIS_FIELD_CANNOT_BE_MORE_THAN_4000_CHARACTERS: "This field cannot be more than 4000 characters",
  THIS_FIELD_CANNOT_BE_MORE_THAN_500_CHARACTERS: "This field cannot be more than 500 characters",

  REQUEST_SEND_FOR_REVIEW_PROGRESS: "Sending request for review",
  REQUEST_SEND_FOR_REVIEW_SUCCESS: "Request is under review",
  REQUEST_SEND_FOR_REVIEW_FAILED: "Failed to send for review",
  REQUEST_APPROVE_REQ_PROGRESS: "Approving request",
  REQUEST_APPROVE_REQ_SUCCESS: "Request approved",
  REQUEST_APPROVE_REQ_FAILED: "Failed to approve request",
  REQUEST_PROGRESS: "Saving request",
  REQUEST_SAVED: "Request Saved",
  REQUEST_DELETE_SURVEY_PROGRESS: "Deleting survey",
  REQUEST_DELETE_SURVEY_SUCCESS: "Survey Deleted",
  REQUEST_DELETE_SURVEY_FAILED: "Failed to delete survey",

  MLC_CSTL_VESSEL_EXPIRED: "The Certificate is overdue for this Vessel. Click on Send to HO for Overdue Concurrence Approval.",
  MAIL_HAS_BEEN_SENT_TO_MLC_CELL_HEAD: "Email has been sent to MLC Cell HO",
  CONCURRENCE_APPROVAL_FAILED: "Concurrence approval failed",
  CONCURRENCE_APPROVAL_SUCCESSFUL: "Concurrence approval successful",
  CONCURRENCE_APPROVAL_INPROGRESS: "Concurrence approval in progress",

  NC_RETURNED: "NC Returned",
  NC_RETURNED_FAILED: "Failed to return NC",

  UNMARKING_SS_REVIEWER_CONFIRM: "The Audit will be available to be picked again for review.",
  UNMARKING_SS_REVIEWER: "Unmarking SS Reviewer",
  UNMARKING_SS_REVIEWER_SUCCESS: "SS Reviewer Unmarked",
  UNMARKING_SS_REVIEWER_FAILED: "Failed to Unmark SS Reviewer",

  SELF_REVIEW_NOT_ALLOWED: "Self Review not allowed",

  SAVE_PR17_REPORTING_PROGRESS: "Saving PR17 report",
  SAVE_PR17_REPORTING_SUCCESS: "PR17 report saved",
  SAVE_PR17_REPORTING_FAILED: "Failed to save PR17 report",

  SUBMIT_PR17_REPORTING_PROGRESS: "Submitting PR17 report",
  SUBMIT_PR17__SEND_FOR_REVIEW_REPORTING_SUCCESS: "PR17 report submitted for review",
  SUBMIT_PR17_REPORTING_SUCCESS: "PR17 report submitted",
  SUBMIT_PR17_REPORTING_FAILED: "Failed to submit PR17 report",

  SAVE_PR17_SCRUTINY_REPORTING_PROGRESS: "Saving PR17 scrutiny report",
  SAVE_PR17_SCRUTINY_REPORTING_SUCCESS: "PR17 scrutiny report saved",
  SAVE_PR17_SCRUTINY_REPORTING_FAILED: "Failed to save PR17 scrutiny report",

  SUBMIT_PR17_SCRUTINY_REPORTING_PROGRESS: "Submitting PR17 scrutiny report",
  SUBMIT_PR17_SCRUTINY__SEND_FOR_REVIEW_REPORTING_SUCCESS: "PR17 scrutiny report submitted for review",
  SUBMIT_PR17_SCRUTINY_REPORTING_SUCCESS: "PR17 scrutiny report submitted",
  SUBMIT_PR17_SCRUTINY_REPORTING_FAILED: "Failed to submit PR17 scrutiny report",

  SAVE_CHECKLIST_PROGRESS: "Saving checklist",
  SAVE_CHECKLIST_SUCCESS: "Checklist saved",
  SAVE_CHECKLIST_FAILED: "Failed to save checklist",

  EXAMINER_ASSIGNED: "Examiner has been successfully assigned.",
  EXAMINER_ASSIGNING: "Assigning Examiner",
  FAILED_TO_ASSIGN_EXAMINER: "Failed to assigned Examiner",

  SERVICE_REQUEST_WILL_BE_CANCELLED: "Service request will be cancelled",
  CANCELLED_SERVICE_REQUEST_PROGRESS: "Cancelling Service Request",
  CANCELLED_SERVICE_REQUEST_SUCCESS: "Service Request cancelled",
  CANCELLED_SERVICE_REQUEST_FAILED: "Cancelling Service Request failed",

  SERVICE_REQUEST_WILL_BE_REJECTED: "Service request will be rejected",
  REJECTED_SERVICE_REQUEST_PROGRESS: "Rejecting Service Request",
  REJECTED_SERVICE_REQUEST_SUCCESS: "Service Request rejected",
  REJECTED_SERVICE_REQUEST_FAILED: "Rejecting Service Request failed",

  SERVICE_REQUEST_WILL_BE_ON_HOLD: "Service request will be on hold",
  ON_HOLD_SERVICE_REQUEST_PROGRESS: "Holding Service Request",
  ON_HOLD_SERVICE_REQUEST_SUCCESS: "Service Request is on hold",
  ON_HOLD_SERVICE_REQUEST_FAILED: "Holding Service Request failed",

  ACCEPTED_SERVICE_REQUEST_PROGRESS: "Accepting Service Request",
  ACCEPTED_SERVICE_REQUEST_SUCCESS: "Service Request Accepted",
  ACCEPTED_SERVICE_REQUEST_FAILED: "Failed to accepted Service Request",

  SAVE_SERVICE_REQUEST_PROGRESS: "Saving Service Request",
  SAVE_SERVICE_REQUEST_SUCCESS: "Service Request Saved",
  SAVE_SERVICE_REQUEST_FAILED: "Failed to Save Service Request",
  BLOCKED_VESSELS_WARNING_MESSAGE: `Owner/Manager of this vessel has been blocked in
navision with the result that no invoices can be raised till
such time that the outstanding amount either part or full
is collected. Please ensure that the outstanding amount is collected
before completion of this survey, to enable removing the
block in navision and thus enabling raising of invoices.`,
  BLOCK_FEE_WARNING: "Block Fee Time is greater than available visit time. Please reduce the Block Fee Time",
  BEFORE_DUE_DATE_WARNING: "Items credited before 15M will be considered in the previous survey-cycle",
  SAVE_ESP_DATA_PROGRESS: "Saving ESP Report data",
  SAVE_ESP_DATA_SUCCESS: "ESP Report data saved",
  SAVE_ESP_DATA_FAILED: "Failed to save ESP Report data",

  SUSPENSION_REOMMENDATION_PROGRESS: "Sending Suspension Recommendation",
  SUSPENSION_REOMMENDATION_SUCCESS: "Sent Suspension Recommendation",
  SUSPENSION_REOMMENDATION_FAILED: "Failed to send Suspension Recommendation",

  PASS_TO_HO_FOR_FT_CERTIFICATE_PREPARATION_PROGRESS: "Passing to HO for FT Certificate Preparation",
  PASS_TO_HO_FOR_FT_CERTIFICATE_PREPARATION_SUCCESS: "Passed to HO for FT Certificate Preparation",
  PASS_TO_HO_FOR_FT_CERTIFICATE_PREPARATION_FAILED: "Failed to pass to HO for FT Certificate Preparation",

  PREPARE_FT_CERTIFICATE_PROGRESS: "Preparing FT Certificate",
  PREPARE_FT_CERTIFICATE_SUCCESS: "FT Certificate Prepared",
  PREPARE_FT_CERTIFICATE_FAILED: "Failed to prepare FT Certificate",

  NO_FT_CERTIFICATE_REQUIRED_PROGRESS: "Bypassing Full Term Certificate Preparation",
  NO_FT_CERTIFICATE_REQUIRED_SUCCESS: "Successfully Bypassed Full Term Certificate Preparation",
  NO_FT_CERTIFICATE_REQUIRED_FAILED: "Failed to Bypass Full Term Certificate Preparation",
  
  ACCEPTING_REVERT_SURVEY_REQUEST_PROGRESS: "Accepting Revert Survey Request",
  ACCEPTING_REVERT_SURVEY_REQUEST_SUCCESS: "Revert Survey Request Accepted",
  ACCEPTING_REVERT_SURVEY_REQUEST_FAILED: "Failed to accept Revert Survey Request",
  REJECTING_REVERT_SURVEY_REQUEST_PROGRESS: "Rejecting Revert Survey Request",
  REJECTING_REVERT_SURVEY_REQUEST_SUCCESS: "Revert Survey Request Rejected",
  REJECTING_REVERT_SURVEY_REQUEST_FAILED: "Failed to reject Revert Survey Request",
  PREPARE_AGREEMENT: "Please Prepare Agreement", 
  SENT_FOR_REVIEW: "Service Report is Sent for Review"
};

export default MESSAGES;
