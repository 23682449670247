import RoutesPrivate from "../components/routes-private";
import withLoadable from "../components/with-loadable";
import Const from "../constants";

const MisReportPage = withLoadable(() => import("../pages/root/mis-report.page"));
const DashboardLayout = withLoadable(() => import("../layouts/dashboard"));

const HelpPage = withLoadable(() => import("../pages/root/help.page"));
const DashboardPage = withLoadable(() => import("../pages/root/dashboard.page"));
const DailyWorkLogPage = withLoadable(() => import("../pages/root/daily-work-log.page"));
const ShipsPage = withLoadable(() => import("../pages/root/ships.page"));
const ShipsSurveyHistoryPage = withLoadable(() => import("../pages/root/ships-survey-history.page"));
const CompaniesSurveyHistoryPage = withLoadable(() => import("../pages/root/companies-survey-history.page"));
const ShipsCompaniesSearchPage = withLoadable(() => import("../pages/root/ships-companies-search.page"));

const IAAuditRequestPage = withLoadable(() => import("../pages/ism-isps-mlc/audit-request.page"));
const IAActivityMonitoringDetailPage = withLoadable(() => import("../pages/ism-isps-mlc/activity-monitoring-detail.page"));
const IAActivityMonitoringPage = withLoadable(() => import("../pages/ism-isps-mlc/activity-monitoring.page"));
const IAActivityMonitoringMentoringPage = withLoadable(() => import("../pages/ism-isps-mlc/activity-monitoring-mentoring.page"));
const IAAddCertificatePage = withLoadable(() => import("../pages/ism-isps-mlc/add-certificate.page"));
const IAAddCertificateConditionalPage = withLoadable(() => import("../pages/ism-isps-mlc/add-certificate-conditional.page"));
const IAAuthorisationLetterPage = withLoadable(() => import("../pages/ism-isps-mlc/authorisation-letter.page"));
const IAAuthorisationLetterTemplatePage = withLoadable(() => import("../pages/ism-isps-mlc/authorisation-letter-template.page"));
const IACertificateDetailPage = withLoadable(() => import("../pages/ism-isps-mlc/certificate-detail.page"));
const IACertificatePage = withLoadable(() => import("../pages/ism-isps-mlc/certificate.page"));
const IAContractReviewDetailPage = withLoadable(() => import("../pages/ism-isps-mlc/contract-review-detail.page"));
const IAContractReviewPage = withLoadable(() => import("../pages/ism-isps-mlc/contract-review.page"));
const IAFormBuilderDetailPage = withLoadable(() => import("../pages/ism-isps-mlc/form-builder-detail.page"));
const IAFormBuilderPage = withLoadable(() => import("../pages/ism-isps-mlc/form-builder.page"));
const IAMyAuditsPage = withLoadable(() => import("../pages/ism-isps-mlc/my-audits.page"));
const IAPendingDocDRMyAuditsPage = withLoadable(() => import("../pages/ism-isps-mlc/pending-doc-dr-my-audits.page"));
const IAPendingAllocationPage = withLoadable(() => import("../pages/ism-isps-mlc/pending-allocation.page"));
const IAPendingDOCDRPage = withLoadable(() => import("../pages/ism-isps-mlc/pending-doc-dr.page"));
const IAPendingCertificateReviewPage = withLoadable(() => import("../pages/ism-isps-mlc/pending-certificates-reviews.page"));
const IAPendingReportPage = withLoadable(() => import("../pages/ism-isps-mlc/pending-report.page"));
const IAPendingReportPreviewPage = withLoadable(() => import("../pages/ism-isps-mlc/pending-reviews-remarks.page"));
const IACertificateReportPreviewPage = withLoadable(() => import("../pages/ism-isps-mlc/certificate-reviews-remarks.page"));
const IAPrepareDigitalCertificatePage = withLoadable(() => import("../pages/ism-isps-mlc/prepare-digital-certificate.page"));
const IASurveyPlanningPage = withLoadable(() => import("../pages/ism-isps-mlc/survey-planning.page"));
const IASurveyReportingFormPage = withLoadable(() => import("../pages/ism-isps-mlc/survey-reporting-form.page"));
const IASurveyReportingPage = withLoadable(() => import("../pages/ism-isps-mlc/survey-reporting.page"));
const IASurveyReportingReportPage = withLoadable(() => import("../pages/ism-isps-mlc/survey-reporting-report.page"));
const IAVerifyDigitalCertificatePage = withLoadable(() => import("../pages/root/verify-digital-certificate.page"));
const IANCScreen = withLoadable(() => import("../pages/ism-isps-mlc/nc-audits.page"));
const IANCClientApprovalPage = withLoadable(() => import("../pages/ism-isps-mlc/nc-survey-reporting.page"));
const IANCFormPage = withLoadable(() => import("../pages/ism-isps-mlc/nc-survey-reporting-form.page"));
const IAFlagInstructionsPage = withLoadable(() => import("../pages/ism-isps-mlc/flag-instructions.page"));
const IASurveyCallBookingForm = withLoadable(() => import("../pages/ism-isps-mlc/survey-call-booking-form.page"));
const IASurveyCallBookingPage = withLoadable(() => import("../pages/ism-isps-mlc/survey-call-booking.page"));
const IACertificateDispatchPage = withLoadable(() => import("../pages/ism-isps-mlc/dispatch-certificate.page"));
const IADispatchLetterTemplatePage = withLoadable(() => import("../pages/ism-isps-mlc/despatch-letter-template.page"));
const IARemoteSoftwarePage = withLoadable(() => import("../pages/ism-isps-mlc/remote-software.page"));
const IACompanyMasterListPage = withLoadable(() => import("../pages/ism-isps-mlc/company-master-list.page"));
const IACompanyMasterAddEditPage = withLoadable(() => import("../pages/ism-isps-mlc/company-master-detail-form.page"));
const TransferCertificatePage = withLoadable(() => import("../pages/ism-isps-mlc/transfer-certificate.page"));
const TransferCertificateListPage = withLoadable(() => import("../pages/ism-isps-mlc/transfer-certificate-listing.page"));

const TAWACertificateDetailPage = withLoadable(() => import("../pages/tawa/approve-certificate.page"));
const TAWACertificatePage = withLoadable(() => import("../pages/tawa/certificate.page"));
const TAWAContractPhasesDetailPage = withLoadable(() => import("../pages/tawa/contract-phases-detail.page"));
const TAWAContractPhasesFormPage = withLoadable(() => import("../pages/tawa/contract-phases-forms.page"));
const TAWAContractReviewDetailPage = withLoadable(() => import("../pages/tawa/contract-review-detail.page"));
const TAWAContractReviewPage = withLoadable(() => import("../pages/tawa/contract-review.page"));
const TAWAMyAuditsPage = withLoadable(() => import("../pages/tawa/my-audits.page"));
const TAWAPendingAllocationPage = withLoadable(() => import("../pages/tawa/pending-allocation.page"));
const TAWAPendingReportPage = withLoadable(() => import("../pages/tawa/pending-report.page"));
const TAWAPendingReportPreviewPage = withLoadable(() => import("../pages/tawa/pending-report-preview.page"));
const TAWASurveyPlanningPage = withLoadable(() => import("../pages/tawa/survey-planning.page"));
const TAWASurveyReportingPage = withLoadable(() => import("../pages/tawa/survey-reporting.page"));
const TAWASurveyReportingFormPage = withLoadable(() => import("../pages/tawa/survey-reporting-form.page"));
const TAWAAuthorisationLetterTemplatePage = withLoadable(() => import("../pages/tawa/authorisation-letter-template.page"));
const TAWASurveyReportingReportPage = withLoadable(() => import("../pages/tawa/survey-reporting-report.page"));
const TAWAAuditRequestTAPage = withLoadable(() => import("../pages/tawa/audit-request-ta.page"));
const TAWAAuditRequestWAPage = withLoadable(() => import("../pages/tawa/audit-request-wa.page"));
const TAWAAuditRequestCertificatePage = withLoadable(() => import("../pages/tawa/audit-request-certificate.page"));
const TAWAPrepareDigitalCertificatePage = withLoadable(() => import("../pages/tawa/prepare-digital-certificate.page"));
const TAWAOngoingVerificationPage = withLoadable(() => import("../pages/tawa/ongoing-verification.page"));
const TAWACertificateAmendPage = withLoadable(() => import("../pages/tawa/amend-certificate.page"));
const TAWAExistingTypeApprovalRequestPage = withLoadable(() => import("../pages/tawa/audit-request-ta.page"));
const TAWAExistingWorkApprovalRequestPage = withLoadable(() => import("../pages/tawa/audit-request-wa.page"));

const TAWAOngoingVerificationPreviewPage = withLoadable(() => import("../pages/tawa/ongoing-verification-preview.page"));

const RPSContractReview = withLoadable(() => import("../pages/rps/contract-review.page"));
const RPSAcceptContractDetailPage = withLoadable(() => import("../pages/rps/contract-review-detail.page"));
const RPSAddContractDetailPage = withLoadable(() => import("../pages/rps/contract-review-request.page"));
const RPSAuthorisationLetterPage = withLoadable(() => import("../pages/rps/authorisation-letter.page"));
const RPSMyAuditPage = withLoadable(() => import("../pages/rps/my-audits.page"));
const RPSSurveyPlanningPage = withLoadable(() => import("../pages/rps/survey-planning.page"));
const RPSPendingReportPage = withLoadable(() => import("../pages/rps/pending-report.page"));
const RPSPendingAllocationPage = withLoadable(() => import("../pages/rps/pending-allocation.page"));
const RPSIAPendingReportPreviewPage = withLoadable(() => import("../pages/rps/pending-reviews-remarks.page"));
const RpsSurveyReportingPage = withLoadable(() => import("../pages/rps/survey-reporting.page"));
const RPSSurveyReportingReportPage = withLoadable(() => import("../pages/rps/survey-reporting-report.page"));
const RpsSurveyReportingFormPage = withLoadable(() => import("../pages/rps/survey-reporting-form.page"));
const RpsAuthorisationLetterTemplatePage = withLoadable(() => import("../pages/rps/authorisation-letter-template.page"));
const RpsCertificatePage = withLoadable(() => import("../pages/rps/certificate.page"));
const RpsAddCertificatePage = withLoadable(() => import("../pages/rps/add-certificate.page"));
const RpsCertificateDetailPage = withLoadable(() => import("../pages/rps/certificate-detail.page"));
const RpsPendingCertificateReviewPage = withLoadable(() => import("../pages/rps/pending-certificates-reviews.page"));
const RpsActivityMonitoringDetailPage = withLoadable(() => import("../pages/rps/activity-monitoring-detail.page"));
const RPSPrepareDigitalCertificatePage = withLoadable(() => import("../pages/rps/prepare-digital-certificate.page"));
const RPSRegisterRequestTypeForm = withLoadable(() => import("../pages/rps/audit-request.page"));
const RpsActivityMonitoringPage = withLoadable(() => import("../pages/rps/activity-monitoring.page"));
const CustomerRegisterationPage = withLoadable(() => import("../pages/root/company-registration.page"));
const CustomerRegistrationDetailPage = withLoadable(() => import("../pages/root/company-registration-detail.page"));
const RPSSuveyQualificationPage = withLoadable(() => import("../pages/rps/survey-qualification.page"));

const SSAContractReviewPage = withLoadable(() => import("../pages/ssa/contract-review.page"));
const SSAAuthorisationLetterPage = withLoadable(() => import("../pages/ssa/authorisation-letter.page"));
const SSAPendingAllocationPage = withLoadable(() => import("../pages/ssa/pending-allocation.page"));
const SSAContractReviewDetailPage = withLoadable(() => import("../pages/ssa/contract-review-detail.page"));
const SSAPendingReportPage = withLoadable(() => import("../pages/ssa/pending-report.page"));
const SSAPendingReportPreviewPage = withLoadable(() => import("../pages/ssa/pending-report-preview.page"));
const SSAMyAuditsPage = withLoadable(() => import("../pages/ssa/my-audits.page"));
const SSASurveyPlanningPage = withLoadable(() => import("../pages/ssa/survey-planning.page"));
const SSACertificatePage = withLoadable(() => import("../pages/ssa/certificate.page"));
const SSASurveyReportingPage = withLoadable(() => import("../pages/ssa/survey-reporting.page"));
const SSASurveyReportingFormPage = withLoadable(() => import("../pages/ssa/survey-reporting-form.page"));
const SSASurveyReportingReportPage = withLoadable(() => import("../pages/ssa/survey-reporting-report.page"));
const SSACertificateDetailPage = withLoadable(() => import("../pages/ssa/certificate-detail.page"));
const SSAPrepareDigitalCertificatePage = withLoadable(() => import("../pages/ssa/prepare-digital-certificate.page"));
const SSAAuthorisationLetterTemplatePage = withLoadable(() => import("../pages/ssa/authorisation-letter-template.page"));
const SsaRegisterRequestForm = withLoadable(() => import("../pages/ssa/audit-request.page"));

const BSAContractReviewPage = withLoadable(() => import("../pages/bsa/contract-review.page"));
const BSAPendingAllocationPage = withLoadable(() => import("../pages/bsa/pending-allocation.page"));
const BSAMyAuditsPage = withLoadable(() => import("../pages/bsa/my-audits.page"));
const BSASurveyPlanningPage = withLoadable(() => import("../pages/bsa/survey-planning.page"));
const BSASurveyReportingPage = withLoadable(() => import("../pages/bsa/survey-reporting.page"));
const BSAAuthorisationLetterPage = withLoadable(() => import("../pages/bsa/authorisation-letter.page"));
const BSAPendingReportPage = withLoadable(() => import("../pages/bsa/pending-report.page"));
const BSAAddContractDetailPage = withLoadable(() => import("../pages/bsa/contract-review-request.page"));
const BSAAuthorisationLetterTemplatePage = withLoadable(() => import("../pages/bsa/authorisation-letter-template.page"));
const BSAPendingReportPreviewPage = withLoadable(() => import("../pages/bsa/pending-report-preview.page"));
const BSASurveyReportingFormPage = withLoadable(() => import("../pages/bsa/survey-reporting-form.page"));
const BSASurveyReportingReportPage = withLoadable(() => import("../pages/bsa/survey-reporting-report.page"));
const BSACertificatePage = withLoadable(() => import("../pages/bsa/certificate.page"));
const BSAAddCertificatePage = withLoadable(() => import("../pages/bsa/add-certificate.page"));
const BSACertificateDetailPage = withLoadable(() => import("../pages/bsa/certificate-detail.page"));
const BSACertificateDuePage = withLoadable(() => import("../pages/bsa/certificate-due.page"));
const BSACertificateDueFormPage = withLoadable(() => import("../pages/bsa/certificate-due-form.page"));
const BSACompanyMasterListPage = withLoadable(() => import("../pages/bsa/company-master-list.page"));
const BSACompanyMasterAddEditPage = withLoadable(() => import("../pages/bsa/company-master-detail-form.page"));
const BSAUploadDueAuditDocumentsPage = withLoadable(() => import("../pages/bsa/upload-due-audit-documents.page"));
const BSACertificateDispatchPage = withLoadable(() => import("../pages/bsa/dispatch-certificate.page"));
const BSAActivityMonitoringPage = withLoadable(() => import("../pages/bsa/survey-qualification.page"));
const BSAActivityMonitoringDetailPage = withLoadable(() => import("../pages/bsa/activity-monitoring-detail.page"));
const BSADispatchLetterTemplatePage = withLoadable(() => import("../pages/bsa/despatch-letter-template.page"));

const ShipInspectionMyAuditsPage = withLoadable(() => import("../pages/ship-inspection/my-audits.page"));
const ShipInspectionSurveyPlanningPage = withLoadable(() => import("../pages/ship-inspection/survey-planning.page"));
const ShipInspectionSurveyReportingPage = withLoadable(() => import("../pages/ship-inspection/survey-reporting.page"));
const ShipInspectionSurveyReportingFormPage = withLoadable(() => import("../pages/ship-inspection/survey-reporting-form.page"));
const ShipInspectionSurveyReportingReportPage = withLoadable(() => import("../pages/ship-inspection/survey-reporting-report.page"));

const PCSCustomerRequestPage = withLoadable(() => import("../pages/pcs/customer-request.page"));
const PCSMyAuditPage = withLoadable(() => import("../pages/pcs/my-audits.page"));
const PCSSurveyPlanningPage = withLoadable(() => import("../pages/pcs/survey-planning.page"));
const PCSCustomerRequestAddPage = withLoadable(() => import("../pages/pcs/customer-request-form.page"));
const PCSCertificatePage = withLoadable(() => import("../pages/pcs/certificate.page"));
const PCSCertificateDetailPage = withLoadable(() => import("../pages/pcs/certificate-detail.page"));
const PCSSurveyReportingPage = withLoadable(() => import("../pages/pcs/survey-reporting.page"));
const PCSSurveyReportingReportPage = withLoadable(() => import("../pages/pcs/survey-reporting-report.page"));
const PCSSurveyReportingFormPage = withLoadable(() => import("../pages/pcs/survey-reporting-form.page"));
const PCSPendingReportPage = withLoadable(() => import("../pages/pcs/pending-report.page"));
const PCSPendingReportPreviewPage = withLoadable(() => import("../pages/pcs/pending-report-preview.page"));
const PCSPrepareDigitalCertificatePage = withLoadable(() => import("../pages/pcs/prepare-digital-certificate.page"));
const PCSPendingAllocationPage = withLoadable(() => import("../pages/pcs/pending-allocation.page"));

const PeriodicalAddRequestPage = withLoadable(() => import("../pages/periodical-survey/survey-request-add.page"));
const PeriodicalPendingAllocationPage = withLoadable(() => import("../pages/periodical-survey/pending-allocation.page"));
const PeriodicalMyAuditPage = withLoadable(() => import("../pages/periodical-survey/my-audits.page"));
const PeriodicalSurveyPlanningPage = withLoadable(() => import("../pages/periodical-survey/survey-planning.page"));
const PeriodicalSurveyRequestFormPage = withLoadable(() => import("../pages/periodical-survey/survey-request-form.page"));
const PeriodicalRequestPage = withLoadable(() => import("../pages/periodical-survey/survey-request.page"));
const PeriodicalSurveyDailyReportsPage = withLoadable(() => import("../pages/periodical-survey/survey-daily-reports.page"));
const PeriodicalQsFormPage = withLoadable(() => import("../pages/periodical-survey/qs-form.page"));
const PeriodicalMSFormPage = withLoadable(() => import("../pages/periodical-survey/ms-form.page"));
const PeriodicalEPSFormPage = withLoadable(() => import("../pages/periodical-survey/eps-form.page"));
const PeriodicalGuidanceFormPage = withLoadable(() => import("../pages/periodical-survey/guidance-form.page"));
const PeriodicalSurveyReportingPage = withLoadable(() => import("../pages/periodical-survey/survey-reporting.page"));
const PeriodicalSurveyReportingItemUpdatePage = withLoadable(() => import("../pages/periodical-survey/survey-reporting-item-update.page"));
const PeriodicalSurveyReportingFormsNReportsListingPage = withLoadable(() => import("../pages/periodical-survey/survey-reporting-forms-reports-listing.page"));
const PeriodicalSurveyReportingFormsNReportsPage = withLoadable(() => import("../pages/periodical-survey/survey-reporting-forms-reports.page"));
const PeriodicalSurveyReportingFormPage = withLoadable(() => import("../pages/periodical-survey/survey-reporting-form.page"));
const PeriodicalSurveyReportReviewPage = withLoadable(() => import("../pages/periodical-survey/pending-report.page"));
const PeriodicalQualificationApproval = withLoadable(() => import("../pages/periodical-survey/qualification-approval.page"));
const PeriodicalActivityMonitoringPage = withLoadable(() => import("../pages/periodical-survey/activity-monitoring.page"));
const PeriodicalActivityMonitoringDetailPage = withLoadable(() => import("../pages/periodical-survey/activity-monitoring-detail.page"));
const PeriodicalCertificatePage = withLoadable(() => import("../pages/periodical-survey/certificates.page"));
const PeriodicalPrepareDigitalCertificatePage = withLoadable(() => import("../pages/periodical-survey/prepare-digital-certificate.page"));
const PeriodicalSurveyorQualificationPage = withLoadable(() => import("../pages/periodical-survey/survey-qualification.page"));
const PeriodicalSurveyorQualificatioDetailPage = withLoadable(() => import("../pages/periodical-survey/survey-qualification-details.page"));

const ShipDetailsPage = withLoadable(() => import("../pages/root/ship-dashboard.page"));
const CertificatesAndRecordsPage = withLoadable(() => import("../pages/root/ship-dashboard.page/certificates-and-records.page"));
const JobHistoryPage = withLoadable(() => import("../pages/root/ship-dashboard.page/job-history.page"));
const PcsFsiDetentionsPage = withLoadable(() => import("../pages/root/ship-dashboard.page/pcs-fsi-detentions.page"));
const PlanStatusPage = withLoadable(() => import("../pages/root/ship-dashboard.page/plan-status.page"));
const NotesAndActionsPage = withLoadable(() => import("../pages/root/ship-dashboard.page/notes-and-actions.page"));
const CommentsPage = withLoadable(() => import("../pages/root/ship-dashboard.page/comments.page"));
const FullSchedulePage = withLoadable(() => import("../pages/root/ship-dashboard.page/full-schedule.page"));
const ShipViewDetailsPage = withLoadable(() => import("../pages/root/ship-dashboard.page/ship-view-details.page"));

const MiscellaneousRequestsPage = withLoadable(() => import("../pages/miscellaneous/survey-requests.page"));
const MiscellaneousRequestAddPage = withLoadable(() => import("../pages/miscellaneous/survey-request.page"));
const MiscellaneousRequestPage = withLoadable(() => import("../pages/miscellaneous/survey-request.page"));
const SurveyPostponementChecklistPage = withLoadable(() => import("../pages/miscellaneous/survey-postponement-checklist.page"));
const SurveyPostponementAuthletterPage = withLoadable(() => import("../pages/miscellaneous/survey-postponement-authletter.page"));
const MiscellaneousCertificatePage = withLoadable(() => import("../pages/miscellaneous/certificates.page"));
const MiscellaneousCertificatePrepareAndAmendPage = withLoadable(() => import("../pages/miscellaneous/prepare-and-amend-cert.page"));
const MiscellaneousRequestAdditionalDetailsPage = withLoadable(() => import("../pages/miscellaneous/additional-details-page"));
const ExtendCocDueDateRequestsPage = withLoadable(() => import("../pages/periodical-survey/extend-coc-due-date-requests.page"));
const ExtendCocDueDateRequestPage = withLoadable(() => import("../pages/periodical-survey/extend-coc-due-date-request.page"));
const PR17SecurityPage = withLoadable(() => import("../pages/periodical-survey/pr17-scrutiny.page"));
const DeleteOverdueSurveyCategoriesRequestPage = withLoadable(() => import("../pages/periodical-survey/delete-overdue-survey-categories-request.page"));

const SRFARegisterRequestTypeForm = withLoadable(() => import("../pages/srfa/audit-request.page"));
const SRFAContractReviewPage = withLoadable(() => import("../pages/srfa/contract-review.page"));
const SRFAContractReviewDetailPage = withLoadable(() => import("../pages/srfa/contract-review-detail.page"));
const SRFAAuthorisationLetterPage = withLoadable(() => import("../pages/srfa/authorisation-letter.page"));
const SRFAPendingAllocationPage = withLoadable(() => import("../pages/srfa/pending-allocation.page"));
const SRFAMyAuditPage = withLoadable(() => import("../pages/srfa/my-audits.page"));
const SRFASurveyPlanningPage = withLoadable(() => import("../pages/srfa/survey-planning.page"));
const SRFASurveyReportingPage = withLoadable(() => import("../pages/srfa/survey-reporting.page"));

const IHMContractReviewPage = withLoadable(() => import("../pages/ihm/contract-review.page"));
const IHMContractReviewDetailsPage = withLoadable(() => import("../pages/ihm/contract-review-request.page"));
const IHMSurveyRequestPage = withLoadable(() => import("../pages/ihm/survey-request.page"));

const ERSClientServiceRequestListingPage = withLoadable(() => import("../pages/ers/service-request-list.page"));
const ERSCustomerRequestPage = withLoadable(() => import("../pages/ers/customer-request.page"));
const ERSCustomerRequestDetailPage = withLoadable(() => import("../pages/ers/customer-request-detail.page"));
const ERSMyJobsPage = withLoadable(() => import("../pages/ers/my-jobs.page"));
const ERSPrepareAgreementPage = withLoadable(() => import("../pages/ers/prepare-agreement.page"));
const ERSServiceRequestDetailPage = withLoadable(() => import("../pages/ers/service-request-detail.page"));
const ERSAddServiceRequestPage = withLoadable(() => import("../pages/ers/add-service-request.page"));
const ERSUploadEmailsPage = withLoadable(() => import("../pages/ers/upload-emails.page"));
const ERSPrepareFtCertificatePage = withLoadable(() => import("../pages/ers/prepare-ft-certificate.page"));
const ERSModelsPage = withLoadable(() => import("../pages/ers/models.page"));
const ERSServiceReportingPage = withLoadable(() => import("../pages/ers/service-reporting.page"));
const ERSReportPreviewPage = withLoadable(() => import("../pages/ers/interim-term-cert.page"));
const ERSPrepareDigitalCertificatePage = withLoadable(() => import("../pages/ers/prepare-digital-certificate.page"));
const ERSPendingReportPage = withLoadable(() => import("../pages/ers/pending-report.page"));
const ERSCertificatePage = withLoadable(() => import("../pages/ers/certificate.page"));
const ERSCertificateDetailPage = withLoadable(() => import("../pages/ers/prepare-ft-certificate.page"));
const WCContractReviewPage = withLoadable(() => import("../pages/welding-consumables/contract-review.page"));
const WCContractDetailPage = withLoadable(() => import("../pages/welding-consumables/contract-review-request.page"));
const WCAuditRequestTAPage = withLoadable(() => import("../pages/welding-consumables/audit-request-wc.page"));
const WCPendingAllocationPage = withLoadable(() => import("../pages/welding-consumables/pending-allocation.page"));
const WCMyAuditsPage = withLoadable(() => import("../pages/welding-consumables/my-audits.page"));
const WCSurveyPlanningPage = withLoadable(() => import("../pages/welding-consumables/survey-planning.page"));
const WCSurveyReportingPage = withLoadable(() => import("../pages/welding-consumables/survey-reporting.page"));
const WCPendingReportPage = withLoadable(() => import("../pages/welding-consumables/pending-report.page"));
const WCCertificatePage = withLoadable(() => import("../pages/welding-consumables/certificate.page"));
const WCCertificateDetailPage = withLoadable(() => import("../pages/welding-consumables/approve-certificate.page"));
const WCAuditRequestCertificatePage = withLoadable(() => import("../pages/welding-consumables/audit-request-certificate.page"));

const {
  SIC,
  SIC_REG,
  SURVEYOR,
  NR,
  ISM_STAFF,
  ISPS_STAFF,
  MLC_STAFF,
  TAWA,
  TAWA_STAFF,
  RPS,
  RPS_STAFF,
  PCS,
  PCS_STAFF,
  BNK,
  BNK_STAFF,
  CLIENT,
  ISM_CLIENT,
  SSA_CLIENT,
  BSA_CLIENT,
  TAWA_CLIENT,
  WC_CLIENT,
  RPS_CLIENT,
  PERIODICAL_CLIENT,
  SS_REVIEWER,
  HOR,
  ISM,
  ISPS,
  MLC,
  HOD,
  ADMIN,
  IA_QUALIFIED_AUDITOR,
  HODIT,
  PCS_QUALIFIED_AUDITOR,
  BSA_QUALIFIED_AUDITOR,
  RPS_QUALIFIED_AUDITOR,
  STAFF,
} = Const.ACCESS;

const TAWARoutes = [
  // NOTE: Need to conform route access for certificates
  { path: "/tawa/:approvalType/certificates", element: TAWACertificatePage },
  {
    path: "/tawa/:approvalType/certificates/:contractReviewId",
    element: TAWACertificateDetailPage,
  },
  {
    path: "/tawa/:approvalType/:contractReviewId/certificates/:certificateId",
    element: TAWACertificateAmendPage,
  },
  { path: "/tawa/certificates/:certificateId/prepare-digital", element: TAWAPrepareDigitalCertificatePage },
  { path: "/tawa/:approvalType/contract-review", element: TAWAContractReviewPage, access: [HOR, TAWA, TAWA_STAFF] },
  { path: "/tawa/:approvalType/contract-review/:contractReviewId", element: TAWAContractReviewDetailPage, access: [HOR, TAWA, TAWA_STAFF] },
  {
    path: "/tawa/:approvalType/contract-review/:contractReviewId/contract-phases",
    element: TAWAContractPhasesDetailPage,
    access: [HOR, TAWA, TAWA_STAFF],
  },
  { path: "/tawa/:approvalType/contract-review/:contractReviewId/contract-phases/forms/:formId", element: TAWAContractPhasesFormPage },
  { path: "/tawa/:approvalType/my-audits", element: TAWAMyAuditsPage },
  { path: "/tawa/:approvalType/my-audits/:surveyStationId/survey-planning", element: TAWASurveyPlanningPage },
  { path: "/tawa/:approvalType/my-audits/:reportHeaderId/survey-reporting", element: TAWASurveyReportingPage },
  { path: "/tawa/:approvalType/my-audits/:reportHeaderId/survey-reporting/:formId", element: TAWASurveyReportingFormPage, access: [SURVEYOR] },
  { path: "/tawa/:approvalType/my-audits/:reportHeaderId/survey-reporting/:formId/report", element: TAWASurveyReportingReportPage, access: [SURVEYOR] },
  { path: "/tawa/:approvalType/pending-allocation", element: TAWAPendingAllocationPage },
  { path: "/tawa/:approvalType/pending-report-audit", element: TAWAPendingReportPage, elementProps: { pageFor: [ADMIN, HOD, HOR] }, access: [ADMIN, HOD, HOR] },
  {
    path: "/tawa/:approvalType/pending-report-audit/:reportHeaderId/forms/:formId/preview",
    element: TAWAPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, HOD, HOR] },
    access: [ADMIN, HOD, HOR],
  },
  {
    path: "/tawa/:approvalType/pending-report-review",
    element: TAWAPendingReportPage,
    elementProps: { pageFor: [SIC, SIC_REG] },
    access: [SIC, SIC_REG],
  },
  { path: "/tawa/:approvalType/ongoing-verification", element: TAWAOngoingVerificationPage, elementProps: { pageFor: [] } },
  {
    path: "/tawa/:approvalType/ongoing-verification-review/:reportHeaderId/forms/:formId/preview",
    element: TAWAOngoingVerificationPreviewPage,
    elementProps: { pageFor: [HOR] },
    access: [HOR],
  },
  {
    path: "/tawa/:approvalType/pending-report-review/:reportHeaderId/forms/:formId/preview",
    element: TAWAPendingReportPreviewPage,
    elementProps: { pageFor: [SIC, SIC_REG] },
    access: [SIC, SIC_REG],
  },
  { path: "/tawa/authorisation-letter-template", element: TAWAAuthorisationLetterTemplatePage, access: [TAWA, TAWA_STAFF, HOR] },
  { path: "/tawa/companies-registration", element: CustomerRegisterationPage },
];

const IARoutes = [
  { path: "/ism-isps-mlc/activity-monitoring", element: IAActivityMonitoringPage, access: [ADMIN, HOD, HOR, SIC, SURVEYOR, SS_REVIEWER, NR] },
  { path: "/ism-isps-mlc/surveyor-qualification", element: IAActivityMonitoringMentoringPage, access: [ADMIN, HOD, HOR, SIC, SURVEYOR, SS_REVIEWER, NR] },
  {
    path: "/ism-isps-mlc/surveyor-qualification/:activityMonitoringId",
    element: IAActivityMonitoringDetailPage,
    access: [ADMIN, HOD, HOR, SIC, SURVEYOR, SS_REVIEWER, NR],
  },
  {
    path: "/ism-isps-mlc/activity-monitoring/:activityMonitoringId",
    element: IAActivityMonitoringDetailPage,
    access: [ADMIN, HOD, HOR, SIC, SIC_REG, SURVEYOR, SS_REVIEWER, NR],
  },
  { path: "/ism-isps-mlc/authorisation-letter-template", element: IAAuthorisationLetterTemplatePage, access: [ISM_STAFF, ISPS_STAFF, MLC_STAFF, HOR] },
  { path: "/ism-isps-mlc/certificates", element: IACertificatePage },
  { path: "/ism-isps-mlc/certificates/add", element: IAAddCertificatePage },
  {
    path: "/ism-isps-mlc/certificates/add/:certificateId/conditional",
    element: IAAddCertificateConditionalPage,
  },
  { path: "/ism-isps-mlc/certificates/:certificateId", element: IACertificateDetailPage },
  {
    path: "/ism-isps-mlc/certificates/:certificateId/prepare-digital",
    element: IAPrepareDigitalCertificatePage,
  },
  {
    path: "/ism-isps-mlc/certificates/:certificateId/verify-digital",
    element: IAVerifyDigitalCertificatePage,
  },
  { path: "/ism-isps-mlc/nc-audits", element: IANCScreen, access: [CLIENT, SIC_REG, SIC, SURVEYOR, SS_REVIEWER, ISM_STAFF, ISPS_STAFF, MLC_STAFF, NR] },
  {
    path: "/ism-isps-mlc/nc-audits/:reportHeaderId/survey-reporting",
    element: IANCClientApprovalPage,
    access: [CLIENT, SIC_REG, SIC, SURVEYOR, SS_REVIEWER, ISM_STAFF, ISPS_STAFF, MLC_STAFF, NR],
  },
  {
    path: "/ism-isps-mlc/nc-audits/:reportHeaderId/survey-reporting/:formId",
    element: IANCFormPage,
    access: [CLIENT, SIC_REG, SIC, SURVEYOR, SS_REVIEWER, ISM_STAFF, ISPS_STAFF, MLC_STAFF, NR],
  },
  { path: "/ism-isps-mlc/contract-review", element: IAContractReviewPage, access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC] },
  {
    path: "/ism-isps-mlc/contract-review/:contractReviewId",
    element: IAContractReviewDetailPage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC, SURVEYOR],
  },
  {
    path: "/ism-isps-mlc/contract-review/:contractReviewId/authorisation-letter",
    element: IAAuthorisationLetterPage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC],
  },
  { path: "/ism-isps-mlc/form-builder", element: IAFormBuilderPage, access: [ADMIN] },
  { path: "/ism-isps-mlc/form-builder/:formId", element: IAFormBuilderDetailPage, access: [ADMIN] },
  { path: "/ism-isps-mlc/my-audits", element: IAMyAuditsPage, access: [SURVEYOR] },
  { path: "/ism-isps-mlc/pending-doc-dr/my-audits", element: IAPendingDocDRMyAuditsPage, access: [SURVEYOR] },
  { path: "/ism-isps-mlc/my-audits/:contractReviewId/survey-planning", element: IASurveyPlanningPage, access: [SURVEYOR] },
  { path: "/ism-isps-mlc/my-audits/:reportHeaderId/survey-reporting", element: IASurveyReportingPage, access: [SURVEYOR] },
  { path: "/ism-isps-mlc/my-audits/:reportHeaderId/survey-reporting/:formId", element: IASurveyReportingFormPage, access: [SURVEYOR, CLIENT] },
  { path: "/ism-isps-mlc/my-audits/:reportHeaderId/survey-reporting/:formId/report", element: IASurveyReportingReportPage, access: [SURVEYOR, CLIENT] },
  { path: "/ism-isps-mlc/pending-allocation", element: IAPendingAllocationPage, access: [SIC, SIC_REG, NR, IA_QUALIFIED_AUDITOR] },
  { path: "/ism-isps-mlc/pending-doc-dr/pending-allocation", element: IAPendingDOCDRPage, access: [SIC, SIC_REG, NR, IA_QUALIFIED_AUDITOR] },
  { path: "/ism-isps-mlc/pending-certificates-review", element: IAPendingCertificateReviewPage, access: [HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF] },
  { path: "/ism-isps-mlc/pending-report-audit", element: IAPendingReportPage, elementProps: { pageFor: [ADMIN, HOD, HOR] }, access: [HOD, HOR] },
  {
    path: "/ism-isps-mlc/pending-report-audit/:reportHeaderId/forms/:formId/preview",
    element: IAPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, HOD, HOR] },
    access: [ADMIN, HOD, HOR],
  },
  {
    path: "/ism-isps-mlc/pending-report-review",
    element: IAPendingReportPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER] },
    access: [SIC, SIC_REG, SS_REVIEWER],
  },
  {
    path: "/ism-isps-mlc/pending-report-review/:reportHeaderId/forms/:formId/preview",
    element: IAPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER] },
    access: [SIC, SIC_REG, SS_REVIEWER],
  },
  { path: "/ism-isps-mlc/flag-instructions", element: IAFlagInstructionsPage, access: [ADMIN, MLC_STAFF, ISM_STAFF, ISPS_STAFF, HOD, HOR] },
  {
    path: "/survey-call-booking",
    element: IASurveyCallBookingPage,
    access: [ADMIN, HOD, HOR, NR, SURVEYOR, SIC, SIC_REG, SS_REVIEWER, ISM_STAFF, ISPS_STAFF, MLC_STAFF],
  },
  {
    path: "/survey-call-booking/add",
    element: IASurveyCallBookingForm,
    access: [ADMIN, HOD, HOR, NR, SURVEYOR, SIC, SIC_REG, SS_REVIEWER, ISM_STAFF, ISPS_STAFF, MLC_STAFF],
  },
  {
    path: "/survey-call-booking/:contractReviewId",
    element: IAContractReviewDetailPage,
    access: [ADMIN, HOD, HOR, NR, SURVEYOR, SIC, SIC_REG, SS_REVIEWER, ISM_STAFF, ISPS_STAFF, MLC_STAFF],
  },
  {
    path: "/ism-isps-mlc/certificates/:certificateId/certificate-dispatch",
    element: IACertificateDispatchPage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC],
  },
  {
    path: "/ism-isps/despatch-letter-template",
    element: IADispatchLetterTemplatePage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC],
  },
  {
    path: "/ism-isps-mlc/certificates/:certificateId/certificate-report-preview",
    element: IACertificateReportPreviewPage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC],
  },
  {
    path: "/ism-isps-mlc/remote-software",
    element: IARemoteSoftwarePage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC, HODIT],
  },
  {
    path: "/ism-isps-mlc/company",
    element: IACompanyMasterListPage,
    access: [ADMIN, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC],
  },
  {
    path: "/ism-isps-mlc/company/:companyId",
    element: IACompanyMasterAddEditPage,
    access: [ADMIN, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC],
  },
  {
    path: "/ism-isps-mlc/company/add",
    element: IACompanyMasterAddEditPage,
    access: [ADMIN],
  },
  {
    path: "/ism-isps-mlc/transfer-certificate/add",
    element: TransferCertificatePage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC, HODIT],
  },
  {
    path: "/ism-isps-mlc/transfer-certificate",
    element: TransferCertificateListPage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC, HODIT],
  },
  {
    path: "/ism-isps-mlc/transfer-certificate/:id",
    element: TransferCertificatePage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC, HODIT],
  },
  {
    path: "/ism-isps-mlc/mis-reports",
    element: MisReportPage,
  },
];

const RPSRoutes = [
  {
    path: "/rps/contract-review",
    element: RPSContractReview,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, RPS, RPS_STAFF],
  },
  {
    path: "/rps/contract-review/add",
    element: RPSAddContractDetailPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, RPS, RPS_STAFF],
  },
  {
    path: "/rps/contract-review/:contractReviewId",
    element: RPSAcceptContractDetailPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, RPS, RPS_STAFF],
  },
  {
    path: "/rps/contract-review/:contractReviewId/authorisation-letter",
    element: RPSAuthorisationLetterPage,
    access: [HOR, RPS, RPS_STAFF],
  },
  {
    path: "/rps/my-audits",
    element: RPSMyAuditPage,
    access: [SURVEYOR],
  },
  {
    path: "/rps/pending-report-review",
    element: RPSPendingReportPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER, NR] },
    access: [SIC, SIC_REG, SS_REVIEWER, NR],
  },
  { path: "/rps/pending-report-audit", element: RPSPendingReportPage, elementProps: { pageFor: [ADMIN, HOD, HOR] }, access: [ADMIN, HOD, HOR] },

  { path: "/rps/my-audits/:contractReviewId/survey-planning", element: RPSSurveyPlanningPage, access: [SURVEYOR] },
  { path: "/rps/my-audits/:reportHeaderId/survey-reporting", element: RpsSurveyReportingPage, access: [SURVEYOR] },
  { path: "/rps/my-audits/:reportHeaderId/survey-reporting/:formId", element: RpsSurveyReportingFormPage, access: [SURVEYOR] },
  { path: "/rps/pending-allocation", element: RPSPendingAllocationPage, access: [SURVEYOR, SIC, SIC_REG, NR, RPS_QUALIFIED_AUDITOR] },
  {
    path: "/rps/pending-report-review/:reportHeaderId/forms/:formId/preview",
    element: RPSIAPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER, NR] },
    access: [SIC, SIC_REG, SS_REVIEWER, NR],
  },
  {
    path: "/rps/pending-report-audit/:reportHeaderId/forms/:formId/preview",
    element: RPSIAPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, HOD, HOR] },
    access: [ADMIN, HOD, HOR],
  },
  {
    path: "/rps/my-audits/:reportHeaderId/survey-reporting/:formId/report",
    element: RPSSurveyReportingReportPage,
    elementProps: { pageFor: [SURVEYOR] },
    access: [SURVEYOR],
  },
  { path: "/rps/authorisation-letter-template", element: RpsAuthorisationLetterTemplatePage, access: [RPS, RPS_STAFF, HOR] },
  { path: "/rps/certificates", element: RpsCertificatePage },
  { path: "/rps/certificates/add", element: RpsAddCertificatePage },
  { path: "/rps/certificates/:certificateId", element: RpsCertificateDetailPage },
  { path: "/rps/pending-certificates-review", element: RpsPendingCertificateReviewPage, access: [HOR, RPS, RPS_STAFF] },
  {
    path: "/rps/activity-monitoring/:activityMonitoringId",
    element: RpsActivityMonitoringDetailPage,
    access: [SIC, SURVEYOR, HOR, RPS, RPS_STAFF],
  },
  { path: "/rps/certificates/:certificateId/prepare-digital", element: RPSPrepareDigitalCertificatePage },
  { path: "/rps/activity-monitoring", element: RpsActivityMonitoringPage, access: [SIC, SURVEYOR, HOR, RPS, RPS_STAFF] },
  { path: "/rps/surveyor-qualification", element: RPSSuveyQualificationPage, access: [ADMIN, HOD, HOR, SIC, SURVEYOR, SS_REVIEWER, NR] },
  {
    path: "/rps/surveyor-qualification/:activityMonitoringId",
    element: RpsActivityMonitoringDetailPage,
    access: [ADMIN, HOD, HOR, SIC, SURVEYOR, SS_REVIEWER, NR],
  },
  { path: "/rps/companies-registration", element: CustomerRegisterationPage },
];

const SSARoutes = [
  {
    path: "/ssa/contract-review",
    element: SSAContractReviewPage,
    access: [HOR, SIC, SIC_REG, NR, ISM_STAFF, SURVEYOR],
  },
  {
    path: "/ssa/customer-request",
    element: SSAContractReviewPage,
    elementProps: { pageFor: [SIC, SIC_REG, NR] },
    access: [ADMIN, HOR, SIC, SIC_REG, NR, ISM_STAFF, SURVEYOR],
  },
  { path: "/ssa/pending-dr", element: SSAPendingAllocationPage, elementProps: { pendingDr: true }, access: [SIC, SIC_REG, NR] },
  { path: "/ssa/pending-allocation", element: SSAPendingAllocationPage, access: [SIC, SIC_REG, NR] },
  {
    path: "/ssa/contract-review/:contractReviewId/authorisation-letter",
    element: SSAAuthorisationLetterPage,
    access: [HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, SURVEYOR],
  },
  { path: "/ssa/authorisation-letter-template", element: SSAAuthorisationLetterTemplatePage, access: [ISM_STAFF, ISPS_STAFF, MLC_STAFF, HOR] },
  { path: "/ssa/certificates/:certificateId", element: SSACertificateDetailPage },
  {
    path: "/ssa/customer-request/:contractReviewId",
    element: SSAContractReviewDetailPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, ISM_STAFF, ISPS_STAFF, MLC_STAFF],
  },
  {
    path: "/ssa/contract-review/add",
    element: SSAContractReviewDetailPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, ISM_STAFF, ISPS_STAFF, MLC_STAFF],
  },
  { path: "/ssa/pending-report-audit", element: SSAPendingReportPage, elementProps: { pageFor: [ADMIN, HOD, HOR] }, access: [ADMIN, HOD, HOR] },
  {
    path: "/ssa/pending-report-audit/:reportHeaderId/forms/:formId/preview",
    element: SSAPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, HOD, HOR] },
    access: [ADMIN, HOD, HOR],
  },
  { path: "/ssa/pending-report-review", element: SSAPendingReportPage, elementProps: { pageFor: [SIC, SIC_REG] }, access: [SIC, SIC_REG] },
  {
    path: "/ssa/pending-report-review/:reportHeaderId/forms/:formId/preview",
    element: SSAPendingReportPreviewPage,
    elementProps: { pageFor: [SIC, SIC_REG] },
    access: [SIC, SIC_REG],
  },
  { path: "/ssa/my-audits", element: SSAMyAuditsPage, access: [SURVEYOR] },
  { path: "/ssa/my-dr-audits", element: SSAMyAuditsPage, elementProps: { drReview: true }, access: [SURVEYOR] },
  { path: "/ssa/my-audits/:contractReviewId/survey-planning", element: SSASurveyPlanningPage, access: [SURVEYOR] },
  { path: "/ssa/certificates", element: SSACertificatePage },
  { path: "/ssa/certificates/:certificateId/prepare-digital", element: SSAPrepareDigitalCertificatePage },
  { path: "/ssa/my-audits/:reportHeaderId/survey-reporting", element: SSASurveyReportingPage, access: [SURVEYOR] },
  { path: "/ssa/my-audits/:reportHeaderId/survey-reporting/:formId", element: SSASurveyReportingFormPage, access: [SURVEYOR] },
  { path: "/ssa/my-audits/:reportHeaderId/survey-reporting/:formId/report", element: SSASurveyReportingReportPage, access: [SURVEYOR] },
  { path: "/ssa/companies-registration", element: CustomerRegisterationPage },
];

const PCSRoutes = [
  {
    path: "/pcs/customer-request",
    element: PCSCustomerRequestPage,
    access: [HOR, SIC, SURVEYOR, PCS_STAFF, ADMIN],
  },
  {
    path: "/pcs/my-audits",
    element: PCSMyAuditPage,
    access: [HOR, SIC, SURVEYOR, ADMIN],
  },
  {
    path: "/pcs/customer-request/add",
    element: PCSCustomerRequestAddPage,
    access: [HOR, SIC, SURVEYOR, PCS_STAFF, ADMIN],
  },
  {
    path: "/pcs/contract-review/:contractReviewId",
    element: PCSCustomerRequestAddPage,
    access: [HOR, SIC, SURVEYOR, PCS, PCS_STAFF],
  },
  { path: "/pcs/certificates", element: PCSCertificatePage },
  { path: "/pcs/certificates/:certificateId", element: PCSCertificateDetailPage },
  {
    path: "/pcs/pending-report-review",
    element: PCSPendingReportPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER, NR] },
    access: [SIC, SIC_REG, SS_REVIEWER, NR],
  },
  {
    path: "/pcs/pending-report-review/:reportHeaderId/forms/:formId/preview",
    element: PCSPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER, NR] },
    access: [SIC, SIC_REG, SS_REVIEWER, NR],
  },
  {
    path: "/pcs/pending-report-audit",
    element: PCSPendingReportPage,
    elementProps: { pageFor: [ADMIN, HOD, HOR] },
    access: [ADMIN, HOD, HOR],
  },
  {
    path: "/pcs/pending-report-audit/:reportHeaderId/forms/:formId/preview",
    element: PCSPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, HOD, HOR] },
    access: [ADMIN, HOD, HOR],
  },
  { path: "/pcs/my-audits/:contractReviewId/survey-planning", element: PCSSurveyPlanningPage, access: [SURVEYOR] },
  { path: "/pcs/my-audits/:reportHeaderId/survey-reporting", element: PCSSurveyReportingPage, access: [SURVEYOR] },
  { path: "/pcs/my-audits/:reportHeaderId/survey-reporting/:formId/report", element: PCSSurveyReportingReportPage, access: [SURVEYOR] },
  { path: "/pcs/my-audits/:reportHeaderId/survey-reporting/:formId", element: PCSSurveyReportingFormPage, access: [SURVEYOR] },
  { path: "/pcs/certificates/:certificateId/prepare-digital", element: PCSPrepareDigitalCertificatePage },
  { path: "/pcs/pending-allocation", element: PCSPendingAllocationPage, access: [SURVEYOR, SIC, SIC_REG, NR, PCS_QUALIFIED_AUDITOR] },
  { path: "/pcs/companies-registration", elementProps: { is_pcs_company: true }, element: CustomerRegisterationPage },
];

const BSARoutes = [
  {
    path: "/bsa/contract-review",
    element: BSAContractReviewPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  {
    path: "/bsa/due-audits",
    element: BSACertificateDuePage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  {
    path: "/bsa/contract-review/:contractReviewId",
    element: BSAAddContractDetailPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  {
    path: "/bsa/due-audits/:companyId",
    element: BSACertificateDueFormPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  { path: "/bsa/pending-allocation", element: BSAPendingAllocationPage, access: [SIC, SIC_REG, NR, BSA_QUALIFIED_AUDITOR] },
  { path: "/bsa/my-audits", element: BSAMyAuditsPage, access: [SURVEYOR] },
  { path: "/bsa/my-audits/:contractReviewId/survey-planning", element: BSASurveyPlanningPage, access: [SURVEYOR] },
  { path: "/bsa/my-audits/:reportHeaderId/survey-reporting", element: BSASurveyReportingPage, access: [SURVEYOR] },
  { path: "/bsa/my-audits/:reportHeaderId/survey-reporting/:formId", element: BSASurveyReportingFormPage, access: [SURVEYOR] },
  {
    path: "/bsa/pending-report-review",
    element: BSAPendingReportPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER, NR] },
    access: [SIC, SIC_REG, SS_REVIEWER, NR],
  },
  { path: "/bsa/my-audits/:reportHeaderId/survey-reporting/:formId/report", element: BSASurveyReportingReportPage, access: [SURVEYOR] },
  {
    path: "/bsa/contract-review/:contractReviewId/authorisation-letter",
    element: BSAAuthorisationLetterPage,
    access: [HOR, BNK, BNK_STAFF],
  },
  { path: "/bsa/pending-report-audit", element: BSAPendingReportPage, elementProps: { pageFor: [ADMIN, HOD, HOR] }, access: [ADMIN, HOD, HOR] },
  {
    path: "/bsa/pending-report-audit/:reportHeaderId/forms/:formId/preview",
    element: BSAPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, HOD, HOR] },
    access: [ADMIN, HOD, HOR],
  },
  {
    path: "/bsa/pending-report-review/:reportHeaderId/forms/:formId/preview",
    element: BSAPendingReportPreviewPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER] },
    access: [SIC, SIC_REG, SS_REVIEWER],
  },
  {
    path: "/bsa/contract-review/add",
    element: BSAAddContractDetailPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  { path: "/bsa/certificates", element: BSACertificatePage },
  { path: "/bsa/certificates/add", element: BSAAddCertificatePage },
  {
    path: "/bsa/certificates/:certificateId",
    element: BSACertificateDetailPage,
    access: [ADMIN, HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  {
    path: "/bsa/authorisation-letter-template",
    element: BSAAuthorisationLetterTemplatePage,
    access: [BNK, BNK_STAFF, HOR],
  },
  {
    path: "/bsa/company",
    element: BSACompanyMasterListPage,
    access: [ADMIN],
  },
  {
    path: "/bsa/company/:companyId",
    element: BSACompanyMasterAddEditPage,
    access: [ADMIN],
  },
  {
    path: "/bsa/company/add",
    element: BSACompanyMasterAddEditPage,
    access: [ADMIN],
  },
  { path: "/audit-request/bsa", element: BSAUploadDueAuditDocumentsPage, access: [BSA_CLIENT] },
  {
    path: "/bsa/certificates/:certificateId/certificate-dispatch",
    element: BSACertificateDispatchPage,
    access: [HOD, HOR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, ISM, ISPS, MLC, BNK_STAFF],
  },
  {
    path: "/audit-request/bsa",
    element: BSAUploadDueAuditDocumentsPage,
    access: [BSA_CLIENT],
  },
  { path: "/bsa/surveyor-qualification", element: BSAActivityMonitoringPage, access: [ADMIN, HOD, HOR, SIC, SURVEYOR, SS_REVIEWER, NR] },
  {
    path: "/bsa/surveyor-qualification/:activityMonitoringId",
    element: BSAActivityMonitoringDetailPage,
    access: [ADMIN, HOD, HOR, SIC, SURVEYOR, SS_REVIEWER, NR],
  },
  { path: "/bsa/companies-registration", elementProps: { is_bsa_company: true }, element: CustomerRegisterationPage },
  { path: "/bsa/mis-reports", element: MisReportPage },
  {
    path: "/bsa/despatch-letter-template",
    element: BSADispatchLetterTemplatePage,
    access: [ADMIN],
  },
];

const SRFARoutes = [
  {
    path: "/srfa/contract-review",
    element: SRFAContractReviewPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  {
    path: "/srfa/contract-review/:contractReviewId",
    element: SRFAContractReviewDetailPage,
    access: [HOD, HOR, SURVEYOR],
  },
  {
    path: "/srfa/contract-review/:contractReviewId/authorisation-letter",
    element: SRFAAuthorisationLetterPage,
    access: [HOD, HOR],
  },
  { path: "/srfa/pending-allocation", element: SRFAPendingAllocationPage, access: [SURVEYOR] },
  {
    path: "/srfa/my-audits",
    element: SRFAMyAuditPage,
    access: [HOR, SIC, SURVEYOR, ADMIN],
  },
  { path: "/srfa/my-audits/:contractReviewId/survey-planning", element: SRFASurveyPlanningPage, access: [SURVEYOR] },
  { path: "/srfa/my-audits/:reportHeaderId/survey-reporting", element: SRFASurveyReportingPage, access: [SURVEYOR] },
]

const ShipInspectionRoutes = [
  { path: "/ship-inspection/my-audits", element: ShipInspectionMyAuditsPage, access: [SURVEYOR] },
  { path: "/ship-inspection/my-audits/:reportHeaderId/survey-planning", element: ShipInspectionSurveyPlanningPage, access: [SURVEYOR] },
  { path: "/ship-inspection/my-audits/:reportHeaderId/survey-reporting", element: ShipInspectionSurveyReportingPage, access: [SURVEYOR] },
  { path: "/ship-inspection/my-audits/:reportHeaderId/survey-reporting/:formId", element: ShipInspectionSurveyReportingFormPage, access: [SURVEYOR] },
  { path: "/ship-inspection/my-audits/:reportHeaderId/survey-reporting/:formId/report", element: ShipInspectionSurveyReportingReportPage, access: [SURVEYOR] },
];

const PeriodicalSurveyRoutes = [
  {
    path: "/periodical-survey/surveyor-qualification",
    element: PeriodicalSurveyorQualificationPage,
    access: [ADMIN, HOD, HOR, SIC, SIC_REG, SURVEYOR, SS_REVIEWER, NR],
  },
  {
    path: "/periodical-survey/surveyor-qualification/:surveyorQualificationId/approval",
    element: PeriodicalSurveyorQualificatioDetailPage,
    access: [ADMIN, HOD, HOR, SIC, SIC_REG, SURVEYOR, SS_REVIEWER, NR],
  },
  {
    path: "/periodical-survey/activity-monitoring",
    element: PeriodicalActivityMonitoringPage,
    access: [ADMIN, HOD, HOR, SIC, SIC_REG, SURVEYOR, SS_REVIEWER, NR],
  },
  {
    path: "/periodical-survey/activity-monitoring/:activityMonitoringId",
    element: PeriodicalActivityMonitoringDetailPage,
    access: [ADMIN, HOD, HOR, SIC, SURVEYOR, SS_REVIEWER, NR],
  },
  {
    path: "/periodical/customer-request",
    element: PeriodicalRequestPage,
    access: [ADMIN, SIC, SIC_REG, SURVEYOR, SS_REVIEWER, NR, STAFF],
  },
  {
    path: "/periodical/survey-request/:SRFId",
    element: PeriodicalAddRequestPage,
    access: [ADMIN, NR, SURVEYOR, SIC, SIC_REG, SS_REVIEWER, HOR],
  },
  {
    path: "/periodical/add-customer-request",
    element: PeriodicalAddRequestPage,
    access: [ADMIN, NR, SURVEYOR, SIC, SIC_REG, SS_REVIEWER],
  },
  {
    path: "/periodical/my-audits/:id/survey-planning",
    element: PeriodicalSurveyPlanningPage,
    access: [ADMIN, NR, SURVEYOR, SIC, SIC_REG, SS_REVIEWER],
  },
  {
    path: "/periodical/my-audits/:surveyRequestId/survey-planning/:visitId/survey-daily-reports",
    element: PeriodicalSurveyDailyReportsPage,
    access: [ADMIN, NR, SURVEYOR, SIC, SIC_REG, SS_REVIEWER, HOR],
  },
  { path: "/periodical/pending-allocation", element: PeriodicalPendingAllocationPage, access: [ADMIN, SURVEYOR, SIC, SIC_REG, NR] },
  {
    path: "/periodical/my-audits",
    element: PeriodicalMyAuditPage,
    access: [ADMIN, NR, SURVEYOR, SIC, SIC_REG, SS_REVIEWER],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting",
    element: PeriodicalSurveyReportingPage,
    access: [ADMIN, HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting/:surveyCategoryId/item-update",
    element: PeriodicalSurveyReportingItemUpdatePage,
    access: [ADMIN, HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting/forms-reports/list",
    element: PeriodicalSurveyReportingFormsNReportsListingPage,
    access: [ADMIN, HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting/forms-reports",
    element: PeriodicalSurveyReportingFormsNReportsPage,
    access: [ADMIN ,HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting/forms-reports/:formId",
    element: PeriodicalSurveyReportingFormPage,
    access: [ADMIN, HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting/qs/:employeeCode",
    element: PeriodicalQsFormPage,
    access: [ADMIN, HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting/ms/:employeeCode",
    element: PeriodicalMSFormPage,
    access: [ADMIN, HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting/esp_reports",
    element: PeriodicalEPSFormPage,
    access: [ADMIN ,HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/my-audits/:reportHeaderId/survey-reporting/guidance/:employeeCode",
    element: PeriodicalGuidanceFormPage,
    access: [ADMIN, HOR, SIC, SURVEYOR],
  },
  {
    path: "/periodical/pending-report-audit",
    element: PeriodicalSurveyReportReviewPage,
    elementProps: { pageFor: [ADMIN, HOD, HOR] },
    access: [ADMIN, HOD, HOR],
  },
  {
    path: "/periodical/pending-report-review",
    element: PeriodicalSurveyReportReviewPage,
    elementProps: { pageFor: [SIC, SIC_REG] },
    access: [ADMIN, SIC, SIC_REG, NR],
  },
  {
    path: "/periodical/qualification-approval",
    element: PeriodicalQualificationApproval,
    elementProps: { pageFor: [SIC, SIC_REG] },
    access: [ADMIN, SIC, SIC_REG],
  },
  {
    path: "/periodical/qualification-surveys/:reportHeaderId/approval/:employeeCode/:isapproval",
    element: PeriodicalQsFormPage,
    elementProps: { pageFor: [SIC, SIC_REG] },
    access: [ADMIN, SIC, SIC_REG],
  },
  { path: "/periodical/certificates", element: PeriodicalCertificatePage, access: [ADMIN, HOD, HOR, SIC, SIC_REG, SURVEYOR, SS_REVIEWER, NR] },
  {
    path: "/periodical/certificates/:certificateId/prepare-digital",
    element: PeriodicalPrepareDigitalCertificatePage,
    access: [ADMIN, HOD, HOR, SIC, SIC_REG, SURVEYOR, SS_REVIEWER, NR],
  },
];

const IHMRoutes = [
  {
    path: "/ihm-rfr/contract-review",
    element: IHMContractReviewPage,
    access: [ADMIN, HOD, HOR, CLIENT],
  },
  {
    path: "/ihm-rfr/contract-review/:contractReviewId",
    element: IHMContractReviewDetailsPage,
    access: [ADMIN, HOD, HOR, CLIENT],
  },
];

const RequestFormsRoutes = [
  { path: "/audit-request/tawa/:approvalType/certificate", element: TAWAAuditRequestCertificatePage, access: [TAWA_CLIENT] },
  { path: "/audit-request/tawa/wa/:companyId", element: TAWAAuditRequestWAPage, access: [TAWA_CLIENT] },
  { path: "/audit-request/tawa/ta/:companyId", element: TAWAAuditRequestTAPage, access: [TAWA_CLIENT] },
  { path: "/audit-request/tawa/ta/:companyId/contract-review/:contractId", element: TAWAExistingTypeApprovalRequestPage, access: [TAWA_CLIENT] },
  { path: "/audit-request/tawa/wa/:companyId/contract-review/:contractId", element: TAWAExistingWorkApprovalRequestPage, access: [TAWA_CLIENT] },
  { path: "/audit-request/tawa/ta", element: TAWAAuditRequestTAPage, access: [TAWA_CLIENT] },
  { path: "/audit-request/tawa/wa", element: TAWAAuditRequestWAPage, access: [TAWA_CLIENT] },
  { path: "/audit-request/:type", element: IAAuditRequestPage, access: [ISM_CLIENT, ADMIN, CLIENT] },
  { path: "/audit-request/rps", element: RPSRegisterRequestTypeForm, access: [RPS_CLIENT] },
  { path: "/audit-request/ssa", element: SsaRegisterRequestForm, access: [SSA_CLIENT] },
  { path: "/survey-request/periodical", element: PeriodicalSurveyRequestFormPage, access: [PERIODICAL_CLIENT,ADMIN] },
  { path: "/audit-request/srfa", element: SRFARegisterRequestTypeForm },
  { path: "/survey-request/ihm", element: IHMSurveyRequestPage },
  { path: "/service-request/ers", element: ERSClientServiceRequestListingPage },
  { path: "/service-request/ers/add", element: ERSAddServiceRequestPage },
  { path: "/service-request/ers/:serviceRequestId/ship/:irNo", element: ERSServiceRequestDetailPage },
  { path: "/audit-request/:approvalType/certificate", element: WCAuditRequestCertificatePage, access: [WC_CLIENT] },

];

const shipDetailsRoutes = [
  { path: "/ships/:irNo/dashboard", element: ShipDetailsPage },
  { path: "/ships/:irNo/view-details", element: ShipViewDetailsPage },
  { path: "/ships/:irNo/certificates-and-records", element: CertificatesAndRecordsPage },
  { path: "/ships/:irNo/job-history", element: JobHistoryPage },
  { path: "/ships/:irNo/psc-fsi-detentions", element: PcsFsiDetentionsPage },
  { path: "/ships/:irNo/plan-status", element: PlanStatusPage },
  { path: "/ships/:irNo/notes-and-actions", element: NotesAndActionsPage },
  { path: "/ships/:irNo/comments", element: CommentsPage },
  { path: "/ships/:irNo/full-schedule", element: FullSchedulePage },
];

const miscellaneousRoutes = [
  { path: "/miscellaneous/requests", element: MiscellaneousRequestsPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/miscellaneous/requests/add", element: MiscellaneousRequestAddPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/miscellaneous/requests/:id", element: MiscellaneousRequestPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/miscellaneous/requests/:id/survey/:reportHeaderId/certificates", element: MiscellaneousCertificatePage, access: [ADMIN, HOD, HOR, STAFF] },
  {
    path: "/miscellaneous/requests/:id/survey/:reportHeaderId/certificates/prepare-and-amend-cert",
    element: MiscellaneousCertificatePrepareAndAmendPage,
    access: [ADMIN, HOD, HOR, STAFF],
  },
  {
    path: "/miscellaneous/requests/:id/survey/:reportHeaderId/additional-details",
    element: MiscellaneousRequestAdditionalDetailsPage,
    access: [ADMIN, HOD, HOR, STAFF],
  },
  {
    path: "/miscellaneous/requests/:id/survey/:reportHeaderId/survey-reporting/:surveyCategoryId/item-update",
    element: PeriodicalSurveyReportingItemUpdatePage,
    access: [ADMIN, HOD, HOR, STAFF],
  },
];

const deleteShipSurveysRoutes = [
  { path: "/delete-ship-surveys/requests", element: MiscellaneousRequestsPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/delete-ship-surveys/requests/add", element: MiscellaneousRequestAddPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/delete-ship-surveys/requests/:id", element: MiscellaneousRequestPage, access: [ADMIN, HOD, HOR, STAFF] },
];

const extendCocDueDateRoutes = [
  { path: "/extend-coc-due-date/requests", element: ExtendCocDueDateRequestsPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/extend-coc-due-date/requests/:reportHeaderId", element: ExtendCocDueDateRequestPage, access: [ADMIN, HOD, HOR, STAFF] },
];

const postponeRoutes = [
  { path: "/survey/postponement-requests", element: MiscellaneousRequestsPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/survey/postponement-requests/add", element: MiscellaneousRequestAddPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/survey/postponement-requests/:id", element: MiscellaneousRequestPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/survey/postponement-requests/:id/checklist/:reportHeaderId", element: SurveyPostponementChecklistPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/survey/:reportHeaderId/postponement-auth-letter/:id", element: SurveyPostponementAuthletterPage, access: [ADMIN, HOD, HOR, STAFF] },
];

const pR17Routes = [
  { path: "/pr-17/requests", element: ExtendCocDueDateRequestsPage, access: [ADMIN, HOD, HOR, STAFF] },
  { path: "/pr-17/requests/:reportHeaderId/deficiency/:deficiencyId", element: PR17SecurityPage, access: [ADMIN, HOD, HOR, STAFF] },
];

const deleteOverdueSurveyCategoriesRoutes = [
  { path: "/delete-overdue-survey-categories/requests", element: ExtendCocDueDateRequestsPage, access: [ADMIN, SIC_REG, HOD, HOR, NR] },
  { path: "/delete-overdue-survey-categories/requests/:surveyRequestId", element: DeleteOverdueSurveyCategoriesRequestPage, access: [ADMIN, SIC_REG, HOD, HOR, NR] },
];

const revertSurveyStatusRequestsRoutes = [
  { path: "/revert-survey-status-requests/requests", element: ExtendCocDueDateRequestsPage, access: [ADMIN, SIC_REG, HOD, HOR, NR] },
];

const ERSRoutes = [
  { path: "/ers/customer-request", element: ERSCustomerRequestPage, access: [HOR, HOD, ADMIN] },
  { path: "/ers/:irNo/customer-request/:serviceRequestId", access: [HOR, HOD, ADMIN], element: ERSCustomerRequestDetailPage },
  { path: "/ers/my-jobs", element: ERSMyJobsPage, access: [ADMIN, HOD, HOR ] },
  { path: "/ers/:irNo/my-jobs/:serviceRequestId/prepare-agreement", element: ERSPrepareAgreementPage, access: [ADMIN, HOD, HOR] },
  { path: "/ers/:irNo/my-jobs/:serviceRequestId/upload-emails", element: ERSUploadEmailsPage, access: [ADMIN, HOD, HOR] },
  { path: "/ers/:irNo/my-jobs/:seviceRequestId/prepare-ft-certificate", element: ERSPrepareFtCertificatePage, access: [ADMIN, HOD, HOR] },
  { path: "/ers/:irNo/my-jobs/:serviceRequestId/models", element: ERSModelsPage, access: [ADMIN, HOD, HOR] },
  { path: "/ers/:irNo/my-jobs/:serviceRequestId/service-reporting", element: ERSServiceReportingPage, access: [ADMIN, HOD, HOR] },
  { path: "/ers/:irNo/my-jobs/:serviceRequestId/service-reporting/:formId", element: ERSReportPreviewPage, access: [ADMIN, HOD, HOR] },
  { path: "/ers/:irNo/my-jobs/:serviceRequestId/service-reporting/:certificateId/digital-certificate", element: ERSPrepareDigitalCertificatePage, access: [ADMIN, HOD, HOR] },
  { path: "/ers/pending-report", element: ERSPendingReportPage, access: [ADMIN, HOD, HOR]},
  { path: "/ers/certificates", element: ERSCertificatePage, access: [ADMIN, HOD, HOR] },
  { path: "/ers/:irNo/service-request/:serviceRequestId/certificates/:certificateId/full-term", element: ERSCertificateDetailPage, access: [ADMIN, HOD, HOR] },
];
const WeldingConsumablesRoutes = [

  { path: "/wc/certificates", element: WCCertificatePage },
  {
    path: "/wc/certificates/:contractReviewId",
    element: WCCertificateDetailPage,
  },
  {
    path: "/welding-consumables/contract-review",
    element: WCContractReviewPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  {
    path: "/bsa/due-audits",
    element: BSACertificateDuePage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  {
    path: "/welding-consumables/contract-review/:contractReviewId",
    element: WCContractDetailPage,
    access: [HOR, SIC, SIC_REG, SURVEYOR, NR, BNK, BNK_STAFF],
  },
  { path: "/audit-request/wc/:companyId", element: WCAuditRequestTAPage, access: [WC_CLIENT] },

  { path: "/wc/pending-allocation", element: WCPendingAllocationPage, access: [SIC, SIC_REG, NR, BSA_QUALIFIED_AUDITOR] },
  { path: "/wc/my-audits", element: WCMyAuditsPage, access: [SURVEYOR] },
  { path: "/wc/my-audits/:contractReviewId/survey-planning", element: WCSurveyPlanningPage, access: [SURVEYOR] },
  { path: "/wc/my-audits/:reportHeaderId/survey-reporting", element: WCSurveyReportingPage, access: [SURVEYOR] },
  {
    path: "/wc/pending-report-review",
    element: WCPendingReportPage,
    elementProps: { pageFor: [ADMIN, SIC, SIC_REG, SS_REVIEWER, NR] },
    access: [SIC, SIC_REG, SS_REVIEWER, NR],
  },

  { path: "/wc/pending-report-audit", element: WCPendingReportPage, elementProps: { pageFor: [ADMIN, HOD, HOR] }, access: [ADMIN, HOD, HOR] },

];
/** * * Routes which are available only after user is logged in */
export default function getPrivateRoutes() {
  return [
    {
      element: RoutesPrivate,
      access: [HOR, SIC, SIC_REG, SURVEYOR, NR, ISM_STAFF, ISPS_STAFF, MLC_STAFF, CLIENT],
      children: [
        {
          element: DashboardLayout,
          children: [
            // __NOTE___: CLIENT Access will be removed
            {
              path: "/dashboard",
              element: DashboardPage,
              elementProps: { pageFor: [ISM, ISPS, MLC, ISM_STAFF, ISPS_STAFF, MLC_STAFF, SIC, SURVEYOR, SIC_REG, HOR, HOD, NR] },
              access: [ISM, ISPS, MLC, ISM_STAFF, ISPS_STAFF, MLC_STAFF, SIC, SURVEYOR, SIC_REG, HOR, HOD, NR],
            },
            { path: "/daily-work-log", element: DailyWorkLogPage, access: [ADMIN, HOD, HOR, SIC, SIC_REG, SURVEYOR, SS_REVIEWER, NR] },
            { path: "/ships", element: ShipsPage },
            { path: "/ships/:shipId/surveys", element: ShipsSurveyHistoryPage },
            { path: "/companies/:companyId/surveys", element: CompaniesSurveyHistoryPage },
            { path: "/surveys/:reportNo", element: ShipsSurveyHistoryPage },
            { path: "/view-reports", element: ShipsCompaniesSearchPage },
            { path: "/companies-registration", element: CustomerRegisterationPage },
            { path: "/companies-registration/:registrationId/detail", element: CustomerRegistrationDetailPage },
            { path: "/companies-registration/detail", element: CustomerRegistrationDetailPage },
            { path: "/help", element: HelpPage },
            ...shipDetailsRoutes,
            ...IARoutes,
            ...TAWARoutes,
            ...RPSRoutes,
            ...SSARoutes,
            ...BSARoutes,
            ...ShipInspectionRoutes,
            ...PCSRoutes,
            ...PeriodicalSurveyRoutes,
            ...RequestFormsRoutes,
            ...miscellaneousRoutes,
            ...deleteShipSurveysRoutes,
            ...extendCocDueDateRoutes,
            ...postponeRoutes,
            ...SRFARoutes,
            ...pR17Routes,
            ...IHMRoutes,
            ...ERSRoutes,
            ...deleteOverdueSurveyCategoriesRoutes,
            ...revertSurveyStatusRequestsRoutes,
            ...WeldingConsumablesRoutes
          ],
        },
      ],
    },
  ];
}
